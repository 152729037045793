import { Grid, Button, Divider, Box, Typography, Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "left",
      maxWidth: "1110px",
      margin: "0 auto",
      padding: "8rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "85%",
      margin: "0 auto 9rem",
      paddingTop: "4rem",
      paddingBottom: "3rem"
    },
    desktopLogoContent: {
      maxWidth: "32rem",
      margin: "2rem 0"
    },
    phoneLogoContent: {
      maxWidth: "27rem",
      margin: "2rem auto 2rem"
    },
    logoButton: {
      color: "white",
      width: "223px",
      height: "56px",
      borderRadius: 0
    },
    desktopLogoImg: {
      position: "relative",
      maxWidth: "600px",
      maxHeight: "600px"
    },
    phoneLogoImg: {
      width: "100%",
      // transform: "scale(1.3)",
      overflowX: "hidden"
    },
    buttonHoverLabel: {
      fontFamily: "Mulish",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.02em"
    }
  }),
);

function HomeLogo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {!phoneMatch && <div className="circle-25" style={{ margin: "-4rem 0rem 0rem 73rem" }} />}
      {phoneMatch && <div className="circle-19" style={{ margin: "-2rem 0rem 0rem 19rem" }} />}
      <Grid
        container={true}
        spacing={3}
        alignItems="center"
        justify="space-between"
      >
        <Grid item={true} xs={12} sm={12} md={6}>
          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bold"
            variant="h4"
            color="primary"
          >
            {t("pages.home.title1")}
          </ResponsiveTypography>

          {phoneMatch ?
            <ResponsiveTypography
              sizes={[48, 48, 32]}
              className="typo typo-weight-bold color color-black typo-partner-phone-logo"
              variant="h2"
            >
              {t("pages.home.title2")}
            </ResponsiveTypography> :
            <ResponsiveTypography
              sizes={[48, 48, 32]}
              className="typo typo-weight-bold color color-black typo-partner-logo"
              variant="h2"
            >
              {t("pages.home.title2")}
            </ResponsiveTypography>
          }

          {phoneMatch ?
            <div className={classes.phoneLogoContent}>
              <ResponsiveTypography className="typo typo-content" sizes={[20, 20, 16]} variant="body1">
                {t("pages.home.title.content")}
              </ResponsiveTypography>
            </div> :
            <div className={classes.desktopLogoContent}>
              <ResponsiveTypography className="typo typo-content" sizes={[20, 20, 16]} variant="body1">
                {t("pages.home.title.content")}
              </ResponsiveTypography>
            </div>
          }
          {phoneMatch && <div className="circle-18" style={{ margin: "3.5rem 0rem 0rem - 0.5rem " }} />}
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Button
                  style={{ width: `${phoneMatch ? "200px" : "234px"}`, height: `${phoneMatch ? "48px" : "56px"}`, color: 'white', paddingRight: '3px', borderRadius: '0', marginTop: '30px', marginBottom: '30px' }}
                  variant="contained"
                  color="primary"
                  {...bindTrigger(popupState)}
                >
                  <span style={{ paddingTop: "13px", paddingBottom: "13px", marginRight: `${phoneMatch ? "2rem" : "3rem"}`, fontSize: "14px" }}>{t("pages.home.title.button.label")}</span>
                  {phoneMatch && <Divider orientation="vertical" flexItem style={{ height: "30px", margin: "auto 0" }} />}
                  {!phoneMatch && <Divider orientation="vertical" flexItem />}
                  <ArrowDropDownIcon style={{ marginLeft: "1rem" }} />
                </Button>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Box p={3} style={{ width: `${phoneMatch ? "12.5rem" : "14.5rem"}`, padding: "24px 0", borderRadius: "0" }}>
                    <Typography>
                      <Link to="/clinics">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content1")}</span>
                      </Link>
                    </Typography>
                    <Typography>
                      <Link to="/patients">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content2")}</span>
                      </Link>
                    </Typography>
                    <Typography>
                      <Link to="/partners">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content3")}</span>
                      </Link>
                    </Typography>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={6}
        >
          <img className={phoneMatch ? classes.phoneLogoImg : classes.desktopLogoImg} src="/home-hero.svg" alt="human" />
          {!phoneMatch && <div className="circle-22" style={{ margin: "-2rem 0rem 0rem 40rem" }}></div>}
          {/* {!phoneMatch && <div className="circle-36" style={{ margin: "2rem 0rem 0rem -43.5rem" }}></div>} */}
        </Grid>
      </Grid>
      {!phoneMatch && <div className="circle-36" style={{ margin: "2rem 0rem 0rem -8rem" }}></div>}
      {phoneMatch && <div className="circle-22" style={{ margin: "0rem 0rem 0rem 19rem" }} />}
    </section >
  );
}

export default HomeLogo;
