import { useTranslation } from "react-i18next";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

export default function PatientFAQ() {
  const { t } = useTranslation();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div style={{ width: `${phoneMatch ? "271px" : "581px"}`, margin: "0 auto 2rem" }}>
      <ResponsiveTypography
        sizes={[36, 36, 28]}
        className="typo typo-weight-bolder"
        variant="h4"
      >
        {t("pages.patient.faq.title")}
      </ResponsiveTypography>
    </div>
  );
}



