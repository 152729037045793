import { useTranslation } from "react-i18next";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

function PartnerPage() {
  const { t } = useTranslation();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <>
      {phoneMatch ?
        <div style={{ width: "343px", textAlign: "center", margin: "0 auto" }}>
          <div style={{ marginBottom: "20px" }}>
            <ResponsiveTypography
              sizes={[28, 28, 24]}
              className="typo typo-weight-bolder"
              variant="h4"

            >
              <span>{t("pages.patient.description.title")}</span>
            </ResponsiveTypography>
          </div>
          <ResponsiveTypography sizes={[16, 16, 16]} variant="body1">
            <span style={{ fontWeight: 400, color: "#727171" }}>{t("pages.patient.description.content")}</span>
          </ResponsiveTypography>
        </div> :
        <div style={{ maxWidth: "549px", textAlign: "center", margin: "8rem auto 0" }}>
          <div style={{ paddingBottom: "20px" }}>
            <ResponsiveTypography
              sizes={[36, 36, 24]}
              className="typo typo-weight-bolder title"
              variant="h4"
            >
              {t("pages.patient.description.title")}
            </ResponsiveTypography>
          </div>
          <div style={{ marginBottom: '1rem', color: "#727171" }}>
            <ResponsiveTypography sizes={[16, 16, 16]} >
              <span style={{ fontWeight: 400 }}>{t("pages.patient.description.content")}</span>
            </ResponsiveTypography>
          </div>
        </div>}
    </>
  );
}

export default PartnerPage;