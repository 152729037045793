import { Typography, TypographyProps } from "@material-ui/core";
import React from "react";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

type ResponsiveTypographyProps = {
  sizes: number[];
  className?: string;
  component?: string;
  fontWeight?: number;
  lineHeight?: number | number[];
};

export const ResponsiveTypography: React.FC<
  ResponsiveTypographyProps & Partial<TypographyProps>
> = ({
  sizes,
  children,
  component = "p" as any,
  fontWeight,
  lineHeight,
  className,
  ...otherProps
}) => {
  const { phoneMatch, tabletMatch } = useCommonScreensMediaQuery();

  const calculateFontSize = () => {
    if (phoneMatch) {
      return sizes[2];
    } else if (tabletMatch) {
      return sizes[1];
    }
    return sizes[0];
  };

  const calculateLineHeight = () => {
    if (!lineHeight) return;
    if (typeof lineHeight === "number") return lineHeight;

    if (phoneMatch) {
      return lineHeight[2];
    } else if (tabletMatch) {
      return lineHeight[1];
    }
    return lineHeight[0];
  };

  return (
    <Typography
      className={`typo ${className}`}
      component={component}
      style={{
        fontSize: calculateFontSize(),
        fontWeight: fontWeight,
        lineHeight: `${calculateLineHeight()}px`,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};
