import { Box, Grid, Typography } from "@material-ui/core";
import { ReactComponent as FacebookIcon } from "assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import { ReactComponent as LinkedInIcon } from "assets/linked_in.svg";
import { ReactComponent as PhoneIcon } from "assets/phone.svg";
import { useTranslation } from "react-i18next";

function ContactUsSocial() {
  const { t } = useTranslation();

  const handleLinkClick = (href: string) => {
    window.open(href, "_blank");
  };

  return (
    <Box
      className="contact-us-social"
      bgcolor="#F9F8F6"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item md={5}>
          <Typography className="typo typo-weight-bolder" variant="h5">
            {t("pages.contactUs.social.title")}
          </Typography>
        </Grid>
        <Grid item md={7}>
          <div className="contact-us-social-grid-wrapper">
            <Box component="div" display="flex" className="contact-us-social-grid-row">
              <div
                className="contact-us-social-grid-item"
                onClick={() => handleLinkClick("https://www.facebook.com/befinamba")}
              >
                <FacebookIcon height="20px" width="20px" />
                <Typography variant="body2" component="span">
                  {t("pages.contactUs.social.facebook")}
                </Typography>
              </div>
              <div
                className="contact-us-social-grid-item"
                onClick={() => handleLinkClick("https://twitter.com/Finamba1")}
              >
                <TwitterIcon height="20px" width="20px" />
                <Typography variant="body2" component="span">
                  {t("pages.contactUs.social.twitter")}
                </Typography>
              </div>
            </Box>
            <Box component="div" display="flex">
              <div
                className="contact-us-social-grid-item"
                onClick={() =>
                  handleLinkClick("https://www.linkedin.com/company/finamba/")
                }
              >
                <div className="linked-in-icon-container">
                  <LinkedInIcon
                    height="10px"
                    width="10px"
                    className="linked-in-icon"
                  />
                </div>
                <Typography variant="body2" component="span">
                  {t("pages.contactUs.social.linkedIn")}
                </Typography>
              </div>
              {/*
              <div className="contact-us-social-grid-item">
                <PhoneIcon height="20px" width="20px" />
                <Typography variant="body2" component="span">
                  +371 29262626
                </Typography>
              </div>
               */}
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactUsSocial;
