import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

import ClinicFocusTitle from "./components/ClinicFocusTitle";
import ClinicFocusTextPanel from "./components/ClinicFocusTextPanel";
import ClinicFocusImage from "./components/ClinicFocusImage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1110px",
      margin: "0 auto 3rem"
    },
    phoneRoot: {
      maxWidth: "90%",
      margin: "0 auto"
    },
  }),
);

function ClinicFocus() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <ClinicFocusTitle />
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} md={6}>
          <ClinicFocusTextPanel />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6}>
          <ClinicFocusImage />
        </Grid>
      </Grid>
    </section>
  );
}

export default ClinicFocus;
