import { useMediaQuery } from "@material-ui/core";
import Footer from "components/organisms/footer/Footer";
import Header from "components/organisms/header/Header";
import { SideMenu } from "components/organisms/side-menu/SideMenu";
import React, { useEffect, useState } from "react";

interface ComponentProps {
  children: React.ReactNode;
}

const MainTemplate: React.FC<ComponentProps> = ({ children }) => {
  const isTablet = useMediaQuery("(max-width: 1250px)");
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const toggleDrawer = (state?: boolean) => {
    setDrawerVisibility((prevState) => state ?? !prevState);
  };

  useEffect(() => {
    if (drawerVisibility && !isTablet) {
      setDrawerVisibility(false);
    }
  }, [isTablet, drawerVisibility]);

  return (
    <React.Fragment>
      <Header onMenuClick={toggleDrawer} />
      <SideMenu
        visible={!!drawerVisibility}
        onClose={() => toggleDrawer(false)}
      />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default MainTemplate;
