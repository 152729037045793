import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopImage: {
      width: "100%",
    },
    phoneImage: {
      width: "60%",
      margin: "4rem auto"
    },
    imgSection: {
      textAlign: "center"
    }
  }),
);

function AsPatientImage() {
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={classes.imgSection}>
      <img className={phoneMatch ? classes.phoneImage : classes.desktopImage} src="/m_f.svg" alt="maleFemale" />
    </div>
  );
}

export default AsPatientImage;