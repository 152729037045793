import { Tab, Tabs } from "@material-ui/core";
import { RouterPath } from "constants/routes.constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useStyles } from "./styles";

const TabPaths = [
  RouterPath.Patients.toString(),
  RouterPath.Clinics.toString(),
  RouterPath.Partners.toString(),
];

export const HeaderTabs: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<string | boolean>(false);

  useEffect(() => {
    if (TabPaths.indexOf(history.location.pathname) >= 0) {
      setTabValue(history.location.pathname);
    } else {
      setTabValue(false);
    }
  }, [history.location.pathname]);

  const handleChangeTab = (event: any, value: string) => {
    history.push(value);
  };
  return (
    <Tabs
      value={tabValue}
      indicatorColor="primary"
      onChange={handleChangeTab}
      className="header-container"
    >
      <Tab
        onClick={onClick}
        label={
          <div>
            <ResponsiveTypography
              sizes={[14, 14, 12]}
              className="typo typo-weight-bold color color-black header-page-title"
              variant="h2"
            >
              {t("header.tabs.creditAndOther.title")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[12, 12, 10]}
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.creditAndOther.subtitle")}
            </ResponsiveTypography>
          </div>
        }
        value={RouterPath.Patients}
        classes={{
          root: classes.tabRoot,
        }}
      />
      <Tab
        onClick={onClick}
        label={
          <div>
            <ResponsiveTypography
              sizes={[14, 14, 12]}
              className="typo typo-weight-bold color color-black header-page-title"
              variant="h2"
            >
              {t("header.tabs.guidesAndTips.title")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[12, 12, 10]}
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.guidesAndTips.subtitle")}
            </ResponsiveTypography>
          </div>
        }
        value={RouterPath.Clinics}
        classes={{
          root: classes.tabRoot,
        }}
      />
      <Tab
        onClick={onClick}
        label={
          <div>
            <ResponsiveTypography
              sizes={[14, 14, 12]}
              className="typo typo-weight-bold color color-black header-page-title"
              variant="h2"
            >
              {t("header.tabs.calculators.title")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[12, 12, 10]}
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.calculators.subtitle")}
            </ResponsiveTypography>
          </div>
        }
        value={RouterPath.Partners}
        classes={{
          root: classes.tabRoot,
        }}
      />
    </Tabs>
  );
};
