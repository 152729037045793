import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

interface ComponentProps {
  iconUrl: string;
  title: string;
  description: string;
}

function ClinicCard(props: ComponentProps) {
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <Box style={{ margin: `${phoneMatch ? "2rem auto" : "4rem auto"}`, maxWidth: `${phoneMatch ? "100%" : "80%"}` }}>
      <div>
        <i />
        <img src={props.iconUrl} alt="icon" />
      </div>
      <Box marginTop={2} marginBottom={2}>
        <ResponsiveTypography
          sizes={[20, 20, 18]}
          className="typo typo-weight-bolder"
          variant="h1"
        >
          <span style={{ lineHeight: `${phoneMatch ? "23px" : "25.1px"}`, }} >{props.title}</span>
        </ResponsiveTypography>
      </Box>
      {phoneMatch ?
        <ResponsiveTypography
          sizes={[16, 16, 16]}
          className="typo typo-clinic-card-phone-content"
        >
          <span style={{ lineHeight: "24px", letterSpacing: "0.02em", color: "#22201E" }}>{props.description}</span>
        </ResponsiveTypography> :
        <ResponsiveTypography
          sizes={[16, 16, 16]}
          className="typo typo-clinic-card-content"
        >
          <span style={{ lineHeight: "24px", letterSpacing: "0.02em", color: "#22201E" }}>{props.description}</span>
        </ResponsiveTypography>
      }
    </Box>
  );
}

export default ClinicCard;
