import PatientLogo from "./components/patient-logo/PatientLogo";
import PatientDescription from "./components/patient-description/PatientDescription";
import PatientReasonValues from "./components/patient-reason/PatientReasonValues";
import PatientFAQ from "./components/patient-faq/PatientFAQ";
import PatientFooter from "./components/Patient-footer/PatientFooter";

function PatientPage() {
  return (
    <div className="home-page" style={{ overflow: "hidden" }}>
      <PatientLogo />
      <PatientDescription />
      <PatientReasonValues />
      <PatientFAQ />
      <PatientFooter />
    </div>
  );
}

export default PatientPage;
