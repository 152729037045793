import { Box, Grid } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";
import PatientReasonCard from "./PatientReasonCard";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

function PatientReasonValues() {
  const { t } = useTranslation();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div style={{ maxWidth: "100%", margin: "0 auto", backgroundColor: "#F8F8F5" }}>
      <Box textAlign="center" style={{ padding: `${phoneMatch ? `2rem 1rem 0` : `8rem 0rem 1rem `}`, maxWidth: "1120px", margin: "4rem auto 9rem" }}>
        <ResponsiveTypography
          sizes={[36, 36, 24]}
          className="typo typo-weight-bolder"
          variant="h4"
        >
          {t("pages.patient.reason.title")}
        </ResponsiveTypography>

        <Grid container={true} spacing={2} style={{ marginTop: `${phoneMatch ? `2rem` : `4rem`}` }}>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PatientReasonCard
              iconUrl="/check.svg"
              title={t("pages.patient.reason.item1.title")}
              description={t("pages.patient.reason.item1.content")}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PatientReasonCard
              iconUrl="/clock.svg"
              title={t("pages.patient.reason.item2.title")}
              description={t("pages.patient.reason.item2.content")}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PatientReasonCard
              iconUrl="/card.svg"
              title={t("pages.patient.reason.item3.title")}
              description={t("pages.patient.reason.item3.content")}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PatientReasonCard
              iconUrl="/support.svg"
              title={t("pages.patient.reason.item4.title")}
              description={t("pages.patient.reason.item4.content")}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default PatientReasonValues;
