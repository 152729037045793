import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Grid } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      position: "relative",
      width: '1110px',
      textAlign: 'center',
      margin: "0 auto",
      paddingBottom: "15px",
      backgroundColor: "#F8F8F5",
      zIndex: 1,
    },
    phoneRoot: {
      position: "relative",
      width: '95%',
      textAlign: 'center',
      margin: "14rem auto 0",
      paddingBottom: "15px",
      backgroundColor: "#F8F8F5",

    },
    label: {
      color: "white",
      fontSize: 14,
    },
    desktopContactButton: {
      borderRadius: 0,
      width: "160px",
      height: "56px",
      margin: "3rem 0 0"
    },
    phoneContactButton: {
      borderRadius: 0,
      width: "160px",
      height: "48px",
      margin: "3rem 0 0"
    },
    content: {
      margin: "2rem 0"
    },
    desktopText: {
      margin: "3rem 0",
      textAlign: "left"
    },
    phoneText: {
      margin: "11rem auto 1.5rem",
      maxWidth: "22rem"
    },
    desktopImg: {
      maxWidth: "100%"
    },
    phoneImg: {
      position: "absolute",
      top: "-85px",
      right: 0,
      left: "40px",
      bottom: 0,
      transform: "scale(0.6)"
    }
  }),
);

function PartnerFooter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} md={5}>
          <img className={phoneMatch ? classes.phoneImg : classes.desktopImg} src="/climber.svg" alt="climber" />
        </Grid>
        <Grid item={true} className={phoneMatch ? classes.phoneText : classes.desktopText} xs={12} sm={12} md={7}>
          <div style={{ width: `${phoneMatch ? "310px" : "90%"}`, margin: `${phoneMatch ? "0 auto" : ""}` }}>
            <ResponsiveTypography
              sizes={[32, 32, 24]}
              className="typo typo-weight-bolder"
              variant="h4"
            >

              {t("pages.partner.footer.title1")}
              {!phoneMatch && <br />}
              {t("pages.partner.footer.title2")}

            </ResponsiveTypography>
          </div>
          <Link to="/contact-us">
            <Button
              variant="contained"
              color="primary"
              className={phoneMatch ? classes.phoneContactButton : classes.desktopContactButton}
            >
              <ResponsiveTypography
                sizes={[14, 14, 12]}
                className={`typo ${classes.label}`}
                variant="h1"
              >
                {t("pages.partner.footer.button.label")}
              </ResponsiveTypography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </section>
  );
}

export default PartnerFooter;