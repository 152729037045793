import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import ClinicCard from "../clinic-card/ClinicCard";
import ClinicCardTitle from "./ClinicCardTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "3rem auto 0",
      padding: "5rem 0 1px"
    },
    phoneRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "3rem auto 5rem",
      paddingBottom: "1px"
    },
    desktopPanel: {
      maxWidth: "1200px",
      margin: "4rem auto 3rem"
    },
    phonePanel: {
      maxWidth: "88%",
      margin: "2rem auto 2rem"
    },
  }),
);

function ClinicCardSecond() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true}>
        <ClinicCardTitle />
      </Grid>
      <div className={phoneMatch ? classes.phonePanel : classes.desktopPanel}>
        <Grid container={true} justify="center">
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/target.svg" title={t("pages.clinic.card.item5.title")} description={t("pages.clinic.card.item5.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/teamwork.svg" title={t("pages.clinic.card.item6.title")} description={t("pages.clinic.card.item6.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/money_bag.svg" title={t("pages.clinic.card.item7.title")} description={t("pages.clinic.card.item7.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/salary.svg" title={t("pages.clinic.card.item8.title")} description={t("pages.clinic.card.item8.content")} />
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/agent.svg" title={t("pages.clinic.card.item9.title")} description={t("pages.clinic.card.item9.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/puzzle_pieces.svg" title={t("pages.clinic.card.item10.title")} description={t("pages.clinic.card.item10.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/credit_card.svg" title={t("pages.clinic.card.item11.title")} description={t("pages.clinic.card.item11.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <ClinicCard iconUrl="/contract.svg" title={t("pages.clinic.card.item12.title")} description={t("pages.clinic.card.item12.content")} />
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default ClinicCardSecond;
