import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

interface ComponentProps {
  imgUrl: string;
  label: string;
  title1: string;
  title2: string;
  content: string;
  textDirection: boolean; // 0: left, 1: right
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "88%",
      margin: "0 auto",
    },
    phoneRoot: {

    },
    desktopDescriptionPanel: {
      marginTop: "-2rem",
      padding: "1rem"
    },
    phoneDescriptionPanel: {
      textAlign: "center",
      margin: "3rem auto"
    },
    desktopTitle: {
      margin: "4px 0px 24px 0px",
    },
    phoneTitle: {
      margin: "8px 0px 12px 0px",
    },
    desktopContent: {
      width: "382px",
      height: "96px",
      color: "#727171"
    },
    phoneContent: {
      textAlign: "center",
      color: "#727171"
    },
    desktopImg: {
      width: "100%",
    },
    phoneImg: {
      width: "100%",
      transform: "scale(1.3)"
    }
  }),
);

function PatientDescriptionPart(props: ComponentProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  const descriptionPanel = () => {
    return (
      <div className={phoneMatch ? classes.phoneDescriptionPanel : classes.desktopDescriptionPanel}>
        <div style={{ marginLeft: `${(!phoneMatch && !props.textDirection) ? "5rem" : "0rem"}` }}>
          <ResponsiveTypography
            sizes={[16, 16, 14]}
            className="typo"
            color="primary"
          >
            {props.label}
          </ResponsiveTypography>
          <ResponsiveTypography
            sizes={[36, 36, 18]}
            className={`typo typo-weight-bolder ${phoneMatch ? classes.phoneTitle : classes.desktopTitle}`}
            variant="h4"
          >
            {props.title1}
            {!phoneMatch && <br />}
            {props.title2}
          </ResponsiveTypography>
          <ResponsiveTypography sizes={[16, 16, 16]} className={phoneMatch ? classes.phoneContent : classes.desktopContent} variant="body1" >
            {props.content}
          </ResponsiveTypography>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.desktopRoot}>
      {phoneMatch ?
        <Grid container={true} justify="center" alignItems="center">
          <Grid item={true} xs={12} sm={12} md={5}>
            {descriptionPanel()}
          </Grid>
          <Grid item={true} xs={12} sm={12} md={7}>
            <img src={props.imgUrl} className={phoneMatch ? classes.phoneImg : classes.desktopImg} />
          </Grid>
        </Grid> :
        <Grid container={true} justify="center" alignItems="center">
          {props.textDirection ?
            // Right Text Panel
            <>
              <Grid item={true} xs={12} sm={12} md={7}>
                <img src={props.imgUrl} className={phoneMatch ? classes.phoneImg : classes.desktopImg} />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={5}>
                {descriptionPanel()}
              </Grid>
            </> :
            // Left Text Panel
            <>
              <Grid item={true} xs={12} sm={12} md={5}>
                {descriptionPanel()}
              </Grid>
              <Grid item={true} xs={12} sm={12} md={7}>
                <img src={props.imgUrl} className={phoneMatch ? classes.phoneImg : classes.desktopImg} />
              </Grid>
            </>
          }
        </Grid>}
    </div>
  );
}

export default PatientDescriptionPart;