import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ClassSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneImage: {
      width: "100%"
    },
    desktopImage: {
      width: "400px",
      height: "400px"
    },
    desktopRoot: {
      textAlign: "center"
    }
  }),
);

function ClinicSolutionImage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={classes.desktopRoot}>
      {
        phoneMatch ?
          <img className={classes.phoneImage} src="/Skateboarder.svg" alt="skaterboarder_image" /> :
          <img className={classes.desktopImage} src="/Skateboarder.svg" alt="skaterboarder_image" />
      }
    </div>
  );
}

export default ClinicSolutionImage;