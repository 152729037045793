import MaterialSelect, { SelectProps } from '@material-ui/core/Select';
import { useStyles } from './styles';


function Select(props: SelectProps) {
    const classes = useStyles();

    return (
        <MaterialSelect
            className={classes.input}
            variant="outlined"
            classes={{
                root: classes.root,
                outlined: classes.outlined,
                select: classes.select

            }}
            inputProps={{
                className: classes.inputUnderline
            }}
            fullWidth={true}
            {...props}
            IconComponent={() => 
                <img className={classes.arrow} src="./assets/ic-rectangle.png" alt="icon" />
            }
        />
    );
}

export default Select;