import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      maxWidth: "477px",
      margin: "4rem auto 0"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "90%",
      margin: "4rem auto 0"
    },
    content: {
      color: "#727171"
    },
  }),
);

function ClinicCardTitle() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <ResponsiveTypography
        sizes={[36, 36, 24]}
        className="typo typo-weight-bold color color-black"
        variant="h2"
      >
        {t("pages.clinic.card.title")}
      </ResponsiveTypography>

      <div className={classes.content}>
        <ResponsiveTypography className="typo typo-content" sizes={[16, 16, 16]} variant="body1">
          <span style={{ fontWeight: 400 }}>{t("pages.clinic.card.content")} </span>
        </ResponsiveTypography>
      </div>
    </section>
  );
}

export default ClinicCardTitle;
