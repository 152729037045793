import { useCallback, useState } from 'react';
import { Box, Grid, MenuItem, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';

import Input from "components/atoms/input/Input";
import Button from "components/atoms/button/Button";
import FormControl from "components/molecules/form-control/FormControl";
import Select from "components/atoms/select/Select";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

import validationSchema from "./validations";
import FormFooterText from './FormFooterText';
import FeedbackModal from '../../FeedbackModal';

interface IContactUs {
  name: string;
  email: string;
  phone: string;
  message: string;
  about: string;
}

function ContactUsForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const onSubmit = (values: IContactUs) => {
    setLoading(true);
    fetch('api/form', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...values }),
    })
      .then(res => res.json())
      .then(() => {
        formik.resetForm();
        setOpenConfirm(true);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      about: "1",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <div>
      <ResponsiveTypography
        sizes={[18, 18, 16]}
        className="mg mg-b-32 typo-weight-normal"
      >
        {t("pages.contactUs.form.title")}
      </ResponsiveTypography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} className="contact-us-form-grid-item">
            <FormControl
              label="This is about"
              labelVariant="caption"
              input={
                <Select fullWidth={true} name="about" value={formik.values.about} onChange={formik.handleChange}>
                  <MenuItem value="1">Finamba in my clinic</MenuItem>
                  <MenuItem value="2">My finance products on Finamba</MenuItem>
                  <MenuItem value="3">I am Finamba customer</MenuItem>
                  <MenuItem value="4">Other</MenuItem>
                </Select>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="contact-us-form-grid-item">
            <FormControl
              label={t("pages.contactUs.form.emailAddress")}
              labelVariant="caption"
              required={true}
              input={
                <Input 
                  type="text" 
                  name="email" 
                  fullWidth={true} 
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  onChange={formik.handleChange} />
              }
              helperText={formik.touched.email ? formik.errors.email : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="contact-us-form-grid-item">
            <FormControl
              label={t("pages.contactUs.form.messageSubject")}
              labelVariant="caption"
              input={
                <Input 
                  type="text" 
                  name="name" 
                  fullWidth={true} 
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  onChange={formik.handleChange} />
              }
              helperText={formik.touched.name ? formik.errors.name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="contact-us-form-grid-item">
            <FormControl
              label={t("pages.contactUs.form.phoneNumber")}
              labelVariant="caption"
              input={
                <Input 
                  type="text" 
                  fullWidth={true} 
                  name="phone"
                  value={formik.values.phone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  onChange={formik.handleChange} />
              }
              helperText={formik.touched.phone ? formik.errors.phone : ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <FormControl
              label={t("pages.contactUs.form.message")}
              labelVariant="caption"
              input={
                <Input 
                  type="text" 
                  fullWidth={true} 
                  multiline={true} 
                  rows={4} 
                  name="message"
                  value={formik.values.message}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  onChange={formik.handleChange} />
              }
              helperText={formik.touched.message ? formik.errors.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className="submit-btn-container desktop">
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ height: 56, width: 240 }}
                type="submit"
              >
                {loading && <CircularProgress color="secondary" size={20} className="loader" />}
                {t("pages.contactUs.form.submit")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <FormFooterText />
        <Box className="submit-btn-container mobile">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ height: 56, width: "100%", marginTop: 32 }}
            type="submit"
          >
            {loading && <CircularProgress color="secondary" size={20} className="loader" />}
            {t("pages.contactUs.form.submit")}
          </Button>
        </Box>
      </form>
      <FeedbackModal open={openConfirm} onClose={() => setOpenConfirm(false)} />
    </div>
  );
}

export default ContactUsForm;
