import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tabRoot: {
    padding: "12px 20px",
    textAlign: "left",
    "& .header-page-title": {
      fontWeight: 800,
    },
    "& .header-page-subtitle": {
      fontWeight: 400,
    },
    "&:hover": {
      background: "#f3f3f3",
    },
  },
}));
