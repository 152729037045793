
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { Link } from "react-router-dom";

interface ComponentProps {
  imgUrl: string;
  topIconUrl: string;
  label: string;
  title1: string;
  title2: string;
  content: string;
  linkIconUrl: string;
  linkLabel: string;
  linkUrl: string;
  textDirection: boolean; // 0: left, 1: right
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1110px",
      margin: "10rem auto"
    },
    phoneRoot: {
      maxWidth: "90%",
      margin: "6rem auto"
    },
    desktopDescriptionPanel: {
      maxWidth: "477px"
    },
    phoneDescriptionPanel: {
      textAlign: "center",
    },
    desktopTitle: {
      paddingTop: "12px",
      paddingBottom: "12px"
    },
    phoneTitle: {
      margin: "8px 0px 20px 0px",
    },
    desktopContent: {
      color: "#727171",
      margin: "12px 0px 40px 0px"
    },
    phoneContent: {
      color: "#727171",
      width: "343px",
      margin: "0 auto 27px",
    },
    desktopRightImg: {
      textAlign: "right",
    },
    desktopLeftImg: {
      marginLeft: "-4rem",
      marginRight: "4rem"
    },
    phoneImg: {
      width: "100%",
    },
    phoneLeftImg: {
    },
    phoneRightImg: {
    },
    topIcon: {
      marginRight: "8px"
    },
    linkIcon: {
      marginRight: "8px"
    },
    desktopTopIconPanel: {
    },
    phoneTopIconPanel: {
      maxWidth: "141px",
      margin: "0 auto",
    },
    desktopLinkIconPanel: {

    },
    phoneLinkIconPanel: {
      maxWidth: "132px",
      margin: "0 auto 63px"
    }
  }),
);

function HomeInfoPart(props: ComponentProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  const descriptionPanel = () => {
    return (
      <div className={phoneMatch ? classes.phoneDescriptionPanel : classes.desktopDescriptionPanel}>
        <div>
          <Box display="flex" className={phoneMatch ? classes.phoneTopIconPanel : classes.desktopTopIconPanel}>
            <img src={props.topIconUrl} className={classes.topIcon} />
            <ResponsiveTypography
              sizes={[16, 16, 14]}
              className="typo"
            >
              {props.label}
            </ResponsiveTypography>
          </Box>
          <ResponsiveTypography
            sizes={[36, 36, 24]}
            className={`typo typo-weight-bolder ${phoneMatch ? classes.phoneTitle : classes.desktopTitle}`}
            variant="h4"
          >
            {props.title1}
            {!phoneMatch && <br />}
            {props.title2}
          </ResponsiveTypography>
          <ResponsiveTypography sizes={[16, 16, 16]} className={phoneMatch ? classes.phoneContent : classes.desktopContent} variant="body1" >
            {props.content}
          </ResponsiveTypography>
          <Box display="flex" className={phoneMatch ? classes.phoneLinkIconPanel : classes.desktopLinkIconPanel}>
            <img src={props.linkIconUrl} className={classes.linkIcon} />
            <ResponsiveTypography sizes={[14, 14, 14]} variant="body1" color="primary">
              <Link to={props.linkUrl}><span id="linkLabel" style={{ cursor: "pointer", fontStyle: "normal", fontWeight: 800, lineHeight: "18px", textAlign: "center", letterSpacing: "0.02em" }}>{props.linkLabel}</span></Link>
            </ResponsiveTypography>
          </Box>
        </div>
      </div>
    )
  }

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {phoneMatch ?
        <Grid container={true} justify="center" alignItems="center">
          <Grid item={true} xs={12} sm={12} md={6}>
            {descriptionPanel()}
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6} style={{ width: "90%", margin: "0 auto" }}>
            <img src={props.imgUrl} className={classes.phoneImg} />
          </Grid>
        </Grid> :
        <Grid container={true} alignItems="center">
          {props.textDirection ?
            // Right Text Panel
            <>
              <Grid item={true} xs={12} sm={12} md={6} className={phoneMatch ? classes.phoneLeftImg : classes.desktopLeftImg}>
                <img src={props.imgUrl} />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                {descriptionPanel()}
              </Grid>
            </> :
            // Left Text Panel
            <>
              <Grid item={true} xs={12} sm={12} md={6}>
                {descriptionPanel()}
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6} className={phoneMatch ? classes.phoneRightImg : classes.desktopRightImg}>
                <img src={props.imgUrl} />
              </Grid>
            </>
          }
        </Grid>}
    </div>
  );
}

export default HomeInfoPart;