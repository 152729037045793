import PatientDescriptionTitle from "./components/patient-description-title/PatientDescriptionTitle";
import ArrowRightIcon from "./ArrowRightIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";

import PatientDescriptionPart from "./components/patient-description-part/PatientDescriptionPart";
import { useTranslation } from "react-i18next";

function PatientDescription() {
  const { t } = useTranslation();

  return (
    <section style={{ maxWidth: "1440px", margin: "0 auto" }}>
      <PatientDescriptionTitle />
      <PatientDescriptionPart
        imgUrl="/phoneQR.svg"
        label={t("pages.patient.description.item1.label")}
        title1={t("pages.patient.description.item1.title1")}
        title2={t("pages.patient.description.item1.title2")}
        content={t("pages.patient.description.item1.content")}
        textDirection={true}
      />
      <ArrowRightIcon />
      <PatientDescriptionPart
        imgUrl="/app-description-1.png"
        label={t("pages.patient.description.item2.label")}
        title1={t("pages.patient.description.item2.title1")}
        title2={t("pages.patient.description.item2.title2")}
        content={t("pages.patient.description.item2.content")}
        textDirection={false}
      />
      <ArrowLeftIcon />
      <PatientDescriptionPart
        imgUrl="/app-description-pick.png"
        label={t("pages.patient.description.item3.label")}
        title1={t("pages.patient.description.item3.title1")}
        title2={t("pages.patient.description.item3.title2")}
        content={t("pages.patient.description.item3.content")}
        textDirection={true}
      />
      <ArrowRightIcon />
      <PatientDescriptionPart
        imgUrl="/app-description-4.png"
        label={t("pages.patient.description.item4.label")}
        title1={t("pages.patient.description.item4.title1")}
        title2={t("pages.patient.description.item4.title2")}
        content={t("pages.patient.description.item4.content")}
        textDirection={false}
      />
      <ArrowLeftIcon />
      <PatientDescriptionPart
        imgUrl="/app-description-5.png"
        label={t("pages.patient.description.item5.label")}
        title1={t("pages.patient.description.item5.title1")}
        title2={t("pages.patient.description.item5.title2")}
        content={t("pages.patient.description.item5.content")}
        textDirection={true}
      />
    </section>
  );
}

export default PatientDescription;