import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";

import { useStyles } from './styles';
import { FeedbackProps } from './interfaces';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

function FeedbackModal({ open, onClose }: FeedbackProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} maxWidth="md" onBackdropClick={onClose}>
      <DialogContent>
        <div className={classes.root}>
          <img src="./assets/ic-success.png" className={classes.icon} alt="ic-success" />
          <ResponsiveTypography
            sizes={[46, 46, 24]}
            variant="h3"
            color="textPrimary"
            gutterBottom={true}
            fontWeight={700}
          >
            {t("pages.contactUs.feedback.getInTouch")}
          </ResponsiveTypography>
          <Typography variant="body2" className={classes.paragraph}>
            {t("pages.contactUs.feedback.content")}
          </Typography>
          <Typography variant="body2">{t("pages.contactUs.feedback.footer")}</Typography>
          <Box component="div" className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={onClose}
            >
              {t("pages.contactUs.feedback.gotIt")}
            </Button>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FeedbackModal;
