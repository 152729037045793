export enum RouterPath {
    Home = '/',
    AboutUs = '/about-us',
    ContactUs = '/contact-us',
    Patients = '/patients',
    Clinics = '/clinics',
    Partners = '/partners',
    AsClinic = "/asclinic",
    AsPatient = "/aspatient",
    AsPartner = "/aspartner"
}