import { Box, Grid } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";
import AboutUsCard from "./AboutUsCard";

interface ICardItem {
  iconUrl: string;
  title: string;
  description: string;
}

function AboutUsValues() {
  const { t } = useTranslation();
  const cardList: ICardItem[] = [
    {
      iconUrl: '/handshake.png',
      title: 'pages.aboutUs.card1.title',
      description: 'pages.aboutUs.card1.content'
    },
    {
      iconUrl: '/growth.png',
      title: 'pages.aboutUs.card2.title',
      description: 'pages.aboutUs.card2.content'
    },
    {
      iconUrl: '/frame.png',
      title: 'pages.aboutUs.card3.title',
      description: 'pages.aboutUs.card3.content'
    },
    {
      iconUrl: '/balance.png',
      title: 'pages.aboutUs.card4.title',
      description: 'pages.aboutUs.card4.content'
    }
  ];

  return (
    <section className="our-values">
      <Box className="content-wrapper" textAlign="center">
        <ResponsiveTypography
          sizes={[32, 32, 24]}
          className="typo typo-weight-semi-bold"
          variant="h4"
        >
          {t("pages.aboutUs.ourValues.title")}
        </ResponsiveTypography>

        <Box marginTop={3} marginBottom={5}>
          <ResponsiveTypography sizes={[18, 18, 16]} className="our-values-content typo-weight-normal">
            {t("pages.aboutUs.ourValues.content")}
          </ResponsiveTypography>
        </Box>

        <Grid container>
          {
            cardList.map((item, i) => (
              <Grid item key={i} xs={12} sm={6} md={3} className="about-us-card-wrapper">
                <AboutUsCard
                  iconUrl={item.iconUrl}
                  title={t(`${item.title}`)}
                  description={t(`${item.description}`)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </section>
  );
}

export default AboutUsValues;
