import React from "react";
import { Route } from "react-router-dom";

import { RouterPath } from "constants/routes.constant";
import MainTemplate from "components/templates/main-template/MainTemplate";
import ScrollToTop from "routes/ScrollToTop";

import HomePage from "pages/home-page/HomePage";
import ContactUsPage from "pages/contact-us-page/ContactUsPage";
import AboutUsPage from "pages/about-us-page/AboutUsPage";
import PatientPage from "pages/patient-page/PatientPage";
import ClinicPage from "pages/clinic-page/ClinicPage";
import PartnerPage from "pages/partner-page/PartnerPage";
import AsPatientPage from "pages/as-patient-page/AsPatientPage";

function Routes() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <MainTemplate>
        <Route exact={true} path={RouterPath.Home} component={HomePage} />
        <Route
          exact={true}
          path={RouterPath.Patients}
          component={PatientPage}
        />
        <Route
          exact={true}
          path={RouterPath.Clinics}
          component={ClinicPage}
        />
        <Route
          exact={true}
          path={RouterPath.Partners}
          component={PartnerPage}
        />
        <Route
          exact={true}
          path={RouterPath.ContactUs}
          component={ContactUsPage}
        />
        <Route
          exact={true}
          path={RouterPath.AsPatient}
          component={AsPatientPage}
        />
        <Route exact={true} path={RouterPath.AboutUs} component={AboutUsPage} />
      </MainTemplate>
    </React.Fragment>
  );
}

export default Routes;
