import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    width: "107.74px",
    height: "24px"
  },
  phoneRoot: {
    width: "84px",
    height: "19px"
  }
}));