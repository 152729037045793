import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      maxWidth: "399px",
      margin: "8rem auto 0rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "343px",
      margin: "8rem auto 4rem"
    },
  }),
);

function ClinicFocusTitle() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <ResponsiveTypography
        sizes={[36, 36, 24]}
        className={`typo typo-weight-bolder`}
        variant="h4"
      >
        <span style={{ lineHeight: `${phoneMatch ? "32px" : "48px"}` }}>{t("pages.clinic.focus.title")}</span>
      </ResponsiveTypography>
    </div>
  );
}

export default ClinicFocusTitle;