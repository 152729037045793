import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      // marginTop: "6rem",
    },
    phoneRoot: {
      textAlign: "center",
      margin: "0 auto"
    },
    desktopTextTitle: {
      maxWidth: "25rem"
    },
    phoneTextTitle: {
      maxWidth: "20rem",
      margin: "0 auto"
    },
    desktopTextContent: {
      maxWidth: "29rem",
      color: "#727171",
      margin: "2rem 0",
    },
    phoneTextContent: {
      maxWidth: "29rem",
      color: "#727171",
      margin: "2rem auto",
    }
  }),
);

function ClinicSolutionTextPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} >
          <ResponsiveTypography
            sizes={[36, 36, 24]}
            className={`typo typo-weight-bold ${phoneMatch ? classes.phoneTextTitle : classes.desktopTextTitle}`}
            variant="h4"
          >
            {t("pages.clinic.solution.title1")}
            {phoneMatch && <br />}
            {t("pages.clinic.solution.title2")}
          </ResponsiveTypography>
          <ResponsiveTypography className={`typo ${phoneMatch ? classes.phoneTextContent : classes.desktopTextContent}`} sizes={[16, 16, 14]} variant="body1">
            <span style={{ fontWeight: 400 }}>{t("pages.clinic.solution.content")}</span>
          </ResponsiveTypography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ClinicSolutionTextPanel;