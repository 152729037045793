import { Button, Typography, FormControl, Select, MenuItem, Grid } from "@material-ui/core";
import React from "react";
import "./HeaderButtons.scss";
import { useTranslation } from "react-i18next";

export const HeaderButtons: React.FC = () => {
  const { t } = useTranslation();
  const [lang, setLang] = React.useState('');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLang(event.target.value as string);
  };

  return (
    <Grid
      container={true}
      className="header-buttons"
      justify="center"
      alignItems="center"
    >
      {/*
      <Typography className="blog" variant="button">
        <span style={{ fontSize: "14px", fontWeight: 800 }}>{t("header.meuns.blog")}</span>
      </Typography>
      <FormControl>
        <Select
          value={lang}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          disableUnderline={true}
          style={{ color: "black" }}
        >
          <MenuItem value="">
            <span style={{ fontSize: "14px", fontWeight: 800 }}>EN</span>
          </MenuItem>
          <MenuItem value={10}><span style={{ fontSize: "14px", fontWeight: 800 }}>LV</span></MenuItem>
        </Select>
      </FormControl>
       
      <Button variant="outlined" size="small">
        {t("header.buttons.login")}
      </Button>
      */}
    </Grid>
  );
};
