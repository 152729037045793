import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      margin: "0 auto"
    },
    textPanel: {
      display: "inline-flex",
    },
    desktopTextTitle: {
      padding: "1rem 0"
    },
    phoneTextTitle: {
      padding: "1rem 0"
    },
    desktopTextContent: {
      maxWidth: "20rem",
      padding: "1rem 2rem"
    },
    phoneTextContent: {
      padding: "1rem 0rem 1rem 2rem"
    },
  }),
);

function ClinicFocusTextPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={classes.desktopRoot}>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={12} className={classes.textPanel} >
          <ResponsiveTypography
            sizes={[40, 40, 32]}
            className={`typo typo-weight-bolder ${phoneMatch ? classes.phoneTextTitle : classes.desktopTextTitle}`}
            variant="h4"
            color="primary"
          >
            {t("pages.clinic.focus.text1.title")}
          </ResponsiveTypography>
          <ResponsiveTypography className={`typo ${phoneMatch ? classes.phoneTextContent : classes.desktopTextContent}`} sizes={[18, 18, 16]} variant="body1">
            {t("pages.clinic.focus.text1.content")}
          </ResponsiveTypography>
        </Grid>
        <Grid item={true} xs={12} sm={12} className={classes.textPanel} >
          <ResponsiveTypography
            sizes={[40, 40, 32]}
            className={`typo typo-weight-bolder ${phoneMatch ? classes.phoneTextTitle : classes.desktopTextTitle}`}
            variant="h4"
            color="primary"
          >
            {t("pages.clinic.focus.text2.title")}
          </ResponsiveTypography>
          <ResponsiveTypography className={`typo ${phoneMatch ? classes.phoneTextContent : classes.desktopTextContent}`} sizes={[18, 18, 16]} variant="body1">
            {t("pages.clinic.focus.text2.content")}
          </ResponsiveTypography>
        </Grid>
        <Grid item={true} xs={12} sm={12} className={classes.textPanel} >
          <ResponsiveTypography
            sizes={[40, 40, 32]}
            className={`typo typo-weight-bolder ${phoneMatch ? classes.phoneTextTitle : classes.desktopTextTitle}`}
            variant="h4"
            color="primary"
          >
            {t("pages.clinic.focus.text3.title")}
          </ResponsiveTypography>
          <ResponsiveTypography className={`typo ${phoneMatch ? classes.phoneTextContent : classes.desktopTextContent}`} sizes={[18, 18, 16]} variant="body1">
            {t("pages.clinic.focus.text3.content")}
          </ResponsiveTypography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ClinicFocusTextPanel;