import {
  Box,
  Divider,
  Drawer,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Tabs,
  Tab
} from "@material-ui/core";
import React from "react";
import "./SideMenu.scss";
import { HeaderTabs } from "./HeaderTabs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type SideMenuProps = {
  visible: boolean;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  en: {
    maxWidth: "90%",
    margin: "2rem auto",
    textAlign: "center"
  },
  lang: {
    maxWidth: "80%",
    margin: "0 auto",
    padding: "1rem"
  }
}));

const otherPages = [
  {
    label: "BLOG",
    href: "/",
  }
];

export const SideMenu: React.FC<SideMenuProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const isPhone = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Drawer
      className="side-menu"
      anchor="right"
      open={visible}
      onClose={onClose}
    >
      <Box
        paddingY={3}
        paddingX={isPhone ? 2 : 4}
        height="100%"
        width="100%"
        boxSizing="border-box"
      >
        <Grid container>
          <Grid item xs={12}>
            <div
              className="header-buttons"
            >
              <Button variant="outlined" size="small">
                {t("header.buttons.login")}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className="tabs-container header-container">
            <HeaderTabs onClick={onClose} />
          </Grid>
          <Divider />
 {/*
          <Grid container spacing={isPhone ? 3 : 4} className="other-pages">
            {otherPages.map((page) => (
              <Grid
                item
                xs={12}
                key={page.label}
                onClick={onClose}
                className="other-page"
              >
                <Link to={page.href}>
                  <Typography style={{ margin: "10px 0", fontWeight: 800, fontSize: "14px", lineHeight: "18px" }}>{t(page.label)}</Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Divider />
         
          <Grid container={true} className={classes.en}>
            <Grid item={true} xs={12}>
              <Tabs id="lang_tab" centered variant="fullWidth">
                <Tab id="lang_tab_en" label='EN' />
                <Tab id="lang_tab_lv" label='LV' />
              </Tabs>
            </Grid>
          </Grid>
           */}
        </Grid>
      </Box>
    </Drawer>
  );
};
