import React from "react";
import { Box, Typography } from "@material-ui/core";

interface ComponentProps {
  iconUrl: string;
  title: string;
  description: string;
}

function AboutUsCard(props: ComponentProps) {
  return (
    <Box className="about-us-card border">
      <div className="about-us-card-header">
        <img src={props.iconUrl} alt="icon" />
      </div>
      <Box marginTop="30px" marginBottom="15px">
        <Typography className="typo typo-weight-semi-bold">
          {props.title}
        </Typography>
      </Box>
      <Typography className="typo typo-weight-normal">{props.description}</Typography>
    </Box>
  );
}

export default AboutUsCard;
