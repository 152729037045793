import PatientAnswerTitle from "./components/PatientAnswerTitle";
import PatientAnswerAccordion from "./components/PatientAnswerAccordion";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "left",
      paddingBottom: "3rem",
      maxWidth: "1470px",
      margin: "0 auto 6rem"
    },
    phoneRoot: {
      textAlign: "left",
      maxWidth: "100%",
      margin: "0 auto"
    },
  }),
);

export default function PatientFAQ() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();
  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <PatientAnswerTitle />
      <PatientAnswerAccordion question={t("pages.patient.faq.item1.question")} answer={t("pages.patient.faq.item1.answer")} />
      <PatientAnswerAccordion question={t("pages.patient.faq.item2.question")} answer={t("pages.patient.faq.item2.answer")} />
      <PatientAnswerAccordion question={t("pages.patient.faq.item3.question")} answer={t("pages.patient.faq.item3.answer")} />
      <PatientAnswerAccordion question={t("pages.patient.faq.item4.question")} answer={t("pages.patient.faq.item4.answer")} />
      <PatientAnswerAccordion question={t("pages.patient.faq.item5.question")} answer={t("pages.patient.faq.item5.answer")} />
    </section>
  );
}