import HomeLogo from "./components/home-logo/HomeLogo";
import HomeFooter from "./components/home-footer/HomeFooter";
import HomeInfoPart from "./components/home-info-part/HomeInfoPart";
import { useTranslation } from "react-i18next";

function HomePage() {
  const { t } = useTranslation();

  return (
    <div className="home-page" style={{ overflow: "hidden" }}>
      <div style={{ backgroundColor: "#F8F8F5" }}>
        <HomeLogo />
      </div>
      <HomeInfoPart
        imgUrl="/sittingTwo.svg"
        topIconUrl="/person_icon.svg"
        label={t("pages.home.everythingYouNeed.item1.label")}
        title1={t("pages.home.everythingYouNeed.item1.title1")}
        title2={t("pages.home.everythingYouNeed.item1.title2")}
        content={t("pages.home.everythingYouNeed.item1.content")}
        linkIconUrl="/right_arrow_icon.svg"
        linkLabel={t("pages.home.everythingYouNeed.item1.link")}
        linkUrl="/patients"
        textDirection={false}
      />
      <HomeInfoPart
        imgUrl="/standupTwo.svg"
        topIconUrl="/medical_services_icon.svg"
        label={t("pages.home.everythingYouNeed.item2.label")}
        title1={t("pages.home.everythingYouNeed.item2.title1")}
        title2={t("pages.home.everythingYouNeed.item2.title2")}
        content={t("pages.home.everythingYouNeed.item2.content")}
        linkIconUrl="/right_arrow_icon.svg"
        linkLabel={t("pages.home.everythingYouNeed.item2.link")}
        linkUrl="/clinics"
        textDirection={true}
      />
      <HomeInfoPart
        imgUrl="/workingPerson.svg"
        topIconUrl="/business_center_icon.svg"
        label={t("pages.home.everythingYouNeed.item3.label")}
        title1={t("pages.home.everythingYouNeed.item3.title1")}
        title2={t("pages.home.everythingYouNeed.item3.title2")}
        content={t("pages.home.everythingYouNeed.item3.content")}
        linkIconUrl="/right_arrow_icon.svg"
        linkLabel={t("pages.home.everythingYouNeed.item3.link")}
        linkUrl="/partners"
        textDirection={false}
      />
      <HomeFooter />
    </div>
  );
}

export default HomePage;
