import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./styles";

function FooterLogo() {
  const matches = useMediaQuery("(min-width: 1100px)");
  const classes = useStyles();
  return (
    <div >
      {
        matches ?
          <Link to="/">
            <img src="footerLogo.svg" alt="logo" style={{ marginBottom: "2.1rem" }} />
          </Link> :
          <Link to="/">
            <img src="/footerLogo.svg" alt="logo" style={{ marginBottom: "2.1rem" }} />
          </Link>
      }
    </div>
  );
}

export default FooterLogo;
