import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import PartnerBenenfitCard from "./components/PartnerBenefitCard";
import PartnerBenefitTitle from "./components/PartnerBenefitTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      margin: "3rem auto 0"
    },
    desktopPanel: {
      maxWidth: "1120px",
      margin: "4rem auto 8rem"
    },
    phonePanel: {
      maxWidth: "95%",
      margin: "0.5rem auto 8rem"
    }
  }),
);

function PartnerBenefit() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={classes.desktopRoot}>
      <Grid container={true}>
        <PartnerBenefitTitle />
      </Grid>
      <div className={phoneMatch ? classes.phonePanel : classes.desktopPanel}>
        <Grid container={true} justify="space-between" alignItems="center">
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerBenenfitCard iconUrl="/round_check.svg" title={t("pages.partner.benefit.item1.title")} description={t("pages.partner.benefit.item1.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerBenenfitCard iconUrl="/round_check.svg" title={t("pages.partner.benefit.item2.title")} description={t("pages.partner.benefit.item2.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerBenenfitCard iconUrl="/round_check.svg" title={t("pages.partner.benefit.item3.title")} description={t("pages.partner.benefit.item3.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerBenenfitCard iconUrl="/round_check.svg" title={t("pages.partner.benefit.item4.title")} description={t("pages.partner.benefit.item4.content")} />
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default PartnerBenefit;
