import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AsPatientTextPanel from "./components/AsPatientTextPanel";
import AsPatientImage from "./components/AsPatientImage";
import { Grid } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1110px",
      margin: "10rem auto 15rem",
      overflow: "hidden"
    },
    phoneRoot: {
      margin: "4rem auto 20rem"
    }
  }),
);

function AsPatientPage() {
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={12} sm={12} md={6}>
          <AsPatientTextPanel />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6}>
          {!phoneMatch && <AsPatientImage />}
        </Grid>
      </Grid>

    </div>
  );
}

export default AsPatientPage;