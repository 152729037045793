import { useTranslation } from "react-i18next";
import { Grid, Button, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1110px",
      margin: "0 auto 4rem"
    },
    phoneRoot: {
      maxWidth: "80%",
      margin: "0 auto 4rem"
    },
    textPanel: {
      maxWidth: "80%",
      margin: "10rem auto"
    },
    title: {
      maxWidth: "90%",
      marginBottom: "2rem"
    },
    content: {
      margin: "2rem 0",
      color: "#727171"
    },
    desktopButton: {
      color: "white",
      borderRadius: 0,
      width: "223px",
      height: "56px"
    },
    phoneButton: {
      color: "white",
      borderRadius: 0,
      width: "222px",
      height: "48px"
    },
    phoneImg: {
      width: "100%"
    },
    phoneTextPanel: {
      maxWidth: "90%",
      margin: "0 auto",
      textAlign: "center"
    },
    phoneTitle: {
      width: "85%",
      margin: "0 auto"
    }
  }),
);

function ClinicPayment() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={classes.desktopRoot}>
      {phoneMatch ?
        <Grid container={true} justify="center" alignItems="center">
          <Grid item={true} xs={12} sm={12} md={6}>
            <Box className={classes.phoneTextPanel}>
              <ResponsiveTypography
                sizes={[36, 36, 24]}
                className={`typo typo-weight-bold ${classes.phoneTitle}`}
                variant="h4"
              >
                {t("pages.clinic.payment.title")}
              </ResponsiveTypography>
              <ResponsiveTypography className={`typo typo-content ${classes.content}`} sizes={[16, 16, 16]} variant="body1">
                <span style={{ fontWeight: 400 }}>{t("pages.clinic.payment.content")}</span>
              </ResponsiveTypography>
              <Link to="/contact-us">
                <Button
                  variant="contained"
                  color="primary"
                  className={phoneMatch ? classes.phoneButton : classes.desktopButton}
                >
                  <span>{t("pages.clinic.payment.button.label")}</span>
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6}>
            <img src="/dancing.svg" alt="dancing" className={classes.phoneImg} />
          </Grid>
        </Grid> :
        <Grid container={true}>
          <Grid item={true} xs={12} sm={12} md={6}>
            <img src="/dancing.svg" alt="dancing" />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6}>
            <Box className={classes.textPanel}>
              <ResponsiveTypography
                sizes={[36, 36, 24]}
                className={`typo typo-weight-bold ${classes.title}`}
                variant="h4"
              >
                {t("pages.clinic.payment.title")}
              </ResponsiveTypography>
              <ResponsiveTypography className={`typo typo-content ${classes.content}`} sizes={[16, 16, 16]} variant="body1">
                <span style={{ fontWeight: 400 }}>{t("pages.clinic.payment.content")}</span>
              </ResponsiveTypography>
              <Link to="/contact-us">
                <Button
                  variant="contained"
                  color="primary"
                  className={phoneMatch ? classes.phoneButton : classes.desktopButton}
                >
                  <span>{t("pages.clinic.payment.button.label")}</span>
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      }
    </section>
  );
}

export default ClinicPayment;