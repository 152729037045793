import { useTranslation } from "react-i18next";
import { Button, Grid, OutlinedInput, InputAdornment, FormControl, NativeSelect, Select, MenuItem, InputLabel } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "381px",
      // margin: "0 auto"
    },
    phoneRoot: {
      maxWidth: "343px",
      margin: "0 auto",
      // textAlign: "center"
    },
    label: {
      paddingBottom: "12px"
    },
    star: {
      color: "primary"
    },
    desktopBtn: {
      color: "white",
      width: "381px",
      height: "56px",
      borderRadius: "0"
    },
    phoneBtn: {
      color: "white",
      width: "343px",
      height: "56px",
      borderRadius: "0"
    },
    btnLabel: {
      padding: "0.1rem"
    },
    phoneTitle: {
      textAlign: "center"
    },
    desktopTitle: {

    }
  }),
);

function PartnerBuyTextPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {!phoneMatch && <div className="circle-44" style={{ margin: "-7rem 0rem 0rem 74rem" }} />}
      {phoneMatch && <div className="circle-21" style={{ margin: "-2.5rem 0rem 0rem 0rem" }} />}
      <div style={{ marginBottom: `${phoneMatch ? "64px" : "48px"}` }} className={phoneMatch ? classes.phoneTitle : classes.desktopTitle}>
        <ResponsiveTypography
          sizes={[36, 36, 24]}
          className="typo typo-weight-bold color color-black"
          variant="h2"
        >
          <span style={{ display: "block", lineHeight: `${phoneMatch ? "32px" : "48px"}`, maxWidth: `${phoneMatch ? "322px" : "100%"}` }} >
            {t("aspatient.text.title")}
          </span>
        </ResponsiveTypography>
      </div>
      {phoneMatch && <div className="circle-15" style={{ margin: "-1.5rem 0rem 0rem 19.5rem" }} />}
      <FormControl fullWidth variant="outlined" style={{ marginBottom: "32px" }}>
        <ResponsiveTypography
          sizes={[14, 14, 14]}
          className={`typo typo-weight-bold color color-black ${classes.label}`}
          variant="h2"
        >
          <span style={{ fontWeight: 600, lineHeight: "18px", letterSpacing: "0.02em" }}>{t("aspatient.text.label1")}<span style={{ color: "#FF8838" }}>*</span></span>
        </ResponsiveTypography>
        <OutlinedInput
          style={{ borderRadius: 0 }}
          id="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" id="select-box-form" style={{ marginBottom: `${phoneMatch ? "32px" : "36px"}` }}>
        <ResponsiveTypography
          sizes={[14, 14, 14]}
          className={`typo typo-weight-bold color color-black ${classes.label}`}
          variant="h2"
        >
          <span style={{ fontWeight: 600, lineHeight: "18px", letterSpacing: "0.02em" }}>{t("aspatient.text.label2")}</span>
        </ResponsiveTypography>

        <Select native defaultValue='none' IconComponent={KeyboardArrowDownIcon}>
          <option value="none" disabled>
            Select
          </option>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={phoneMatch ? classes.phoneBtn : classes.desktopBtn}
      >
        <span className={classes.btnLabel} >{t("aspatient.text.button.label")}</span>
      </Button>
      {!phoneMatch && <div className="circle-22" style={{ margin: "7rem 0rem 0rem -4rem" }} />}
    </section >
  );
}

export default PartnerBuyTextPanel;
