import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      padding: 40,
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
    },
    icon: {
      width: 150,
      height: 150,
      marginBottom: 40,
      backgroundColor: "#ffffff",
      [theme.breakpoints.down('md')]: {
        width: 100,
        height: 100,
      },
    },
    paragraph: {
      marginBottom: 10,
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
      width: "100%",
    },
    button: {
      color: '#ffffff',
      height: 56, 
      width: 150,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);
