import { Grid } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";

function AboutUsHero() {
  const { t } = useTranslation();

  return (
    <section className="about-us-hero">
      <div className="content-wrapper">
        <Grid
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className="about-us-hero-text"
          >
            <ResponsiveTypography
              sizes={[32, 32, 24]}
              className="typo typo-weight-bolder title"
              variant="h4"
            >
              {t("pages.aboutUs.title")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[18, 18, 16]}
              className="typo typo-weight-semi-bold our-mission-text"
              variant="h6"
              gutterBottom={true}
            >
              {t("pages.aboutUs.ourMission.title")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[18, 18, 16]}
              className="typo typo-weight-normal par1 our-mission-content"
              variant="h6"
              component="p"
            >
              {t("pages.aboutUs.ourMission.content1")}
            </ResponsiveTypography>
            <ResponsiveTypography
              sizes={[18, 18, 16]}
              className="typo typo-weight-normal par2 our-mission-content"
              variant="h6"
              component="p"
            >
              {t("pages.aboutUs.ourMission.content2")}
            </ResponsiveTypography>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={8}
          >
            <div className="about-us-hero-container-img">
              <img src="/about-us-01.png" alt="about-us-bg" />
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default AboutUsHero;
