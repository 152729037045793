import { useTranslation } from "react-i18next";
import { Grid, Divider, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      maxWidth: "1125px",
      margin: "5rem auto",
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "85%",
      margin: "0 auto 4rem",
      // paddingTop: "1rem"
    },
    item: {
      margin: "2rem 0"
    },
    divider: {
      width: "120px",
      margin: "0 auto"
    }
  }),
);

function ClinicPercentage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} md={4}>
          <Box className={classes.item}>
            <ResponsiveTypography
              sizes={[48, 48, 36]}
              className="typo typo-weight-bold"
              variant="h4"
            >
              {t("pages.clinic.percentage.item1.title")}
            </ResponsiveTypography>
            <ResponsiveTypography className="typo typo-content" sizes={[18, 18, 18]} variant="body1">
              {t("pages.clinic.percentage.item1.content")}
            </ResponsiveTypography>
          </Box>
          {phoneMatch && <Divider variant="middle" className={classes.divider} />}
        </Grid>
        <Grid item={true} xs={12} sm={12} md={4}>
          <Box borderColor={phoneMatch ? "white" : "#b0aeae"} border={1} borderTop={0} borderBottom={0} className={classes.item}>
            <ResponsiveTypography
              sizes={[48, 48, 36]}
              className="typo typo-weight-bold"
              variant="h4"
            >
              {t("pages.clinic.percentage.item2.title")}
            </ResponsiveTypography>
            <ResponsiveTypography className="typo typo-content" sizes={[18, 18, 18]} variant="body1">
              {t("pages.clinic.percentage.item2.content")}
            </ResponsiveTypography>
          </Box>
          {phoneMatch && <Divider variant="middle" className={classes.divider} />}
        </Grid>
        <Grid item={true} xs={12} sm={12} md={4}>
          <Box className={classes.item}>
            <ResponsiveTypography
              sizes={[48, 48, 36]}
              className="typo typo-weight-bold"
              variant="h4"
            >
              {t("pages.clinic.percentage.item3.title")}
            </ResponsiveTypography>
            <ResponsiveTypography className="typo typo-content" sizes={[18, 18, 18]} variant="body1">
              {t("pages.clinic.percentage.item3.content")}
            </ResponsiveTypography>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}

export default ClinicPercentage;