import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./styles";

function Logo() {
  const matches = useMediaQuery("(min-width: 1100px)");
  const classes = useStyles();
  return (
    <div >
      {
        matches ?
          <Link to="/">
            <img src="/finamba-logo1.svg" className={classes.desktopRoot} alt="logo" />
          </Link> :
          <Link to="/">
            <img src="/finamba-logo1.svg" className={classes.phoneRoot} alt="logo" />
          </Link>
      }
    </div>
  );
}

export default Logo;
