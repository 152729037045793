import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "600px",
      maxHeight: "600px"
    },
    phoneRoot: {
      width: "100%",
      transform: "scale(1.2)",
    },
    image: {
      width: "100%"
    }
  }),
);

function ClinicFocusImage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <img className={classes.image} src="/garden.svg" alt="focus_image" />
    </div>
  );
}

export default ClinicFocusImage;