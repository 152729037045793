import useMediaQuery from "@material-ui/core/useMediaQuery";

export const useCommonScreensMediaQuery = () => {
  const phoneMatch = useMediaQuery("(max-width: 768px)");
  const tabletMatch = useMediaQuery("(max-width: 1440px)");
  const desktopMatch = useMediaQuery("(min-width: 1441px)");

  return {
    phoneMatch,
    tabletMatch,
    desktopMatch,
  };
};
