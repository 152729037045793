import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

interface ComponentProps {
  title: string;
  subtitle: string;
  description: string;
}

function PartnerBuyCard(props: ComponentProps) {
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <Box style={{ margin: `${phoneMatch ? "1rem auto 0" : "8px"}`, maxWidth: `${phoneMatch ? "343px" : "363px"}`, height: "272px" }} border={2} borderColor="primary.main">
      <Box marginTop={6} marginBottom={2}>
        <ResponsiveTypography
          sizes={[36, 36, 30]}
          className={`typo typo-weight-bold`}
          variant="h4"
          color="primary"
        >
          {props.title}
        </ResponsiveTypography>
        <ResponsiveTypography className="typo typo-weight-bold typo-mt-1" sizes={[18, 18, 16]}>
          {props.subtitle}
        </ResponsiveTypography>
      </Box>
      <Typography style={{ color: "#727171", maxWidth: "299px", margin: "0 auto", fontSize: "16px", fontWeight: 400 }}>{props.description}</Typography>
    </Box>
  );
}

export default PartnerBuyCard;
