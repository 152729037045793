import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function FormFooterText() {
	const { t } = useTranslation();

	return (
		<Box marginTop={3}>
			<Typography variant="body2" className="typo typo-weight-medium text-required">
				{t("pages.contactUs.form.requiredField")}
			</Typography>
			<Typography variant="body2" className="typo typo-weight-medium">
				{t("pages.contactUs.form.footerText")}
			</Typography>
		</Box>
	)
}

export default FormFooterText;