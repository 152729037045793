import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import ClinicCard from "../clinic-card/ClinicCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "2rem auto",
      padding: "1rem 0 2rem"
    },
    phoneRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "2rem auto",
      width: "100%"
    },
    desktopPanel: {
      maxWidth: "1190px",
      margin: "4rem auto 0"
    },
    phonePanel: {
      maxWidth: "90%",
      margin: "4rem auto 0"
    },
    card: {
      margin: "1rem auto"
    }
  }),
);

function ClinicCardFirst() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" className={phoneMatch ? classes.phonePanel : classes.desktopPanel}>
        <Grid item={true} xs={12} sm={6} md={3} className={classes.card}>
          <ClinicCard iconUrl="/wallet.svg" title={t("pages.clinic.card.item1.title")} description={t("pages.clinic.card.item1.content")} />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} className={classes.card}>
          <ClinicCard iconUrl="/paper_bag.svg" title={t("pages.clinic.card.item2.title")} description={t("pages.clinic.card.item2.content")} />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} className={classes.card}>
          <ClinicCard iconUrl="/save_money.svg" title={t("pages.clinic.card.item3.title")} description={t("pages.clinic.card.item3.content")} />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} className={classes.card}>
          <ClinicCard iconUrl="/achievement.svg" title={t("pages.clinic.card.item4.title")} description={t("pages.clinic.card.item4.content")} />
        </Grid>
      </Grid>
    </section>
  );
}

export default ClinicCardFirst;
