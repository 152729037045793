import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    // maxWidth: "1920px",
    padding: 1,
    backgroundColor: "#201f1a"
  },
  desktopMain: {
    maxWidth: "1340px",
    margin: "0 auto",
    padding: "0 2rem"
  },
  phoneMain: {
    maxWidth: "90%",
    margin: "0 auto"
  },
  image: {
    marginRight: "1rem"
  },
  twitterIcon: {
    marginRight: "1rem"
  },
  how: {
    lineHeight: "2rem",
  }
}));
