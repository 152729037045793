import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

interface ComponentProps {
  iconUrl: string;
  title: string;
  description: string;
}

function PartnerReasonCard(props: ComponentProps) {
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <Box style={{ margin: `${phoneMatch ? "0 auto 4rem" : "4rem auto"}`, maxWidth: `${phoneMatch ? "343px" : "240px"}` }}>
      <div>
        <i />
        <img src={props.iconUrl} alt="icon" />
      </div>
      <Box marginTop={2} marginBottom={2}>
        <ResponsiveTypography className={`typo typo-weight-bold`} sizes={[20, 20, 18]} variant="body1">
          <span style={{ lineHeight: `${phoneMatch ? "23px" : "25px"}`, letterSpacing: "0.02em", color: "#22201E" }}>{props.title}</span>
        </ResponsiveTypography>
      </Box>
      <ResponsiveTypography className={`typo typo-weight-normal`} sizes={[16, 16, 16]} variant="body1">
        <span style={{ lineHeight: "24px", letterSpacing: "0.02em", }}>{props.description}</span>
      </ResponsiveTypography>
    </Box>
  );
}

export default PartnerReasonCard;
