import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      width: "1110px",
      margin: "0 auto 8rem"
    },
    phoneRoot: {
      textAlign: "center",
      width: "90%",
      margin: "0 auto 8rem"
    },
    title: {
      marginBottom: "2rem"
    }
  }),
);

function PartnerIcons() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <ResponsiveTypography
        sizes={[32, 32, 24]}
        variant="h4"
        className={`typo typo-weight-bolder ${classes.title}`}
      >
        {t("pages.partner.icons.title")}
      </ResponsiveTypography>
      <Grid container={true}>
        <Grid item={true} xs={4} sm={4} md={4}>
          <img src="/feldmana.svg" style={{ width: `${phoneMatch && "100%"}` }} alt="Feldmana" />
        </Grid>
        <Grid item={true} xs={4} sm={4} md={4}>
          <img src="/pines.svg" style={{ width: `${phoneMatch && "100%"}` }} alt="Apines" />
        </Grid>
        <Grid item={true} xs={4} sm={4} md={4}>
          <img src="/gfitness.svg" style={{ width: `${phoneMatch && "100%"}` }} alt="G-Fitness" />
        </Grid>
        {/*
        <Grid item={true} xs={6} sm={6} md={3}>
          <img src="/rodke.svg" style={{ width: `${phoneMatch && "100%"}` }} alt="rodke" />
        </Grid>
        <Grid item={true} xs={6} sm={6} md={3}>
          <img src="/centrs.svg" style={{ width: `${phoneMatch && "100%"}` }} alt="centrs" />
        </Grid>
        */}
      </Grid>
    </section>
  );
}

export default PartnerIcons;
