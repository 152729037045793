import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      position: "absolute",
      marginLeft: "29rem"
    },
    phoneRoot: {
      position: "absolute",
      marginTop: "1.5rem",
      marginLeft: "1rem"
    },
    iconImage: {
      marginTop: "-5rem"
    }
  }),
);

function ArrowRightIcon() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();
  const matches = useMediaQuery("(min-width: 1200px)");

  return (
    <>
      {
        !matches && !phoneMatch ? <></> : <>
          {phoneMatch ?
            <div className={classes.phoneRoot}>
              <img src="/phone_arrow_right.svg" className={classes.iconImage} alt="phone_arrow_right" />
            </div> :
            <div className={classes.desktopRoot}>
              <img src="/right_arrow.svg" className={classes.iconImage} alt="arrow_right" />
            </div>}
        </>
      }
    </>
  );
}

export default ArrowRightIcon;