import { Tab, Tabs, Typography } from "@material-ui/core";
import { RouterPath } from "constants/routes.constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";

const TabPaths = [
  RouterPath.Patients.toString(),
  RouterPath.Clinics.toString(),
  RouterPath.Partners.toString(),
];

export const HeaderTabs: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<string | boolean>(false);

  useEffect(() => {
    if (TabPaths.indexOf(history.location.pathname) >= 0) {
      setTabValue(history.location.pathname);
    } else {
      setTabValue(false);
    }
  }, [history.location.pathname]);

  const handleChangeTab = (event: any, value: string) => {
    history.push(value);
  };
  return (
    <Tabs
      value={tabValue}
      indicatorColor="primary"
      onChange={handleChangeTab}
      className="header-tabs"
    >
      <Tab
        onClick={onClick}
        label={
          <div className="typo-align-left">
            <Typography
              variant="body1"
              color="textPrimary"
              className="header-page-title"
            >
              {t("header.tabs.creditAndOther.title")}
            </Typography>
            <Typography
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.creditAndOther.subtitle")}
            </Typography>
          </div>
        }
        value={RouterPath.Patients}
        classes={{
          root: classes.tabRoot,
        }}
      />
      <Tab
        onClick={onClick}
        label={
          <div className="typo-align-left">
            <Typography
              variant="body1"
              color="textPrimary"
              className="header-page-title"
            >
              {t("header.tabs.guidesAndTips.title")}
            </Typography>
            <Typography
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.guidesAndTips.subtitle")}
            </Typography>
          </div>
        }
        value={RouterPath.Clinics}
        classes={{
          root: classes.tabRoot,
        }}
      />
      <Tab
        onClick={onClick}
        label={
          <div className="typo-align-left">
            <Typography
              variant="body1"
              color="textPrimary"
              className="header-page-title"
            >
              {t("header.tabs.calculators.title")}
            </Typography>
            <Typography
              className="typo-transform--init header-page-subtitle"
              variant="subtitle1"
              color="textSecondary"
            >
              {t("header.tabs.calculators.subtitle")}
            </Typography>
          </div>
        }
        value={RouterPath.Partners}
        classes={{
          root: classes.tabRoot,
        }}
      />
    </Tabs>
  );
};
