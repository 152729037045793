import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  desktopRoot: {
    textAlign: "center",
    maxWidth: "43%",
    margin: "4rem auto 0"
  },
  phoneRoot: {
    textAlign: "center",
    maxWidth: "95%",
    margin: "4rem auto 0"
  }
}));