import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.08)",
    maxWidth: "100%",
    margin: "0 auto"
  },
  phoneRoot: {
    maxWidth: "60%"
  },
  main: {
    maxWidth: "1110px",
    margin: "0 auto",
    textAlign: "right",
  },
  phoneMain: {
    maxWidth: "77%",
    margin: "0 auto",
    height: "56px"
  },
  icon: {
    position: "absolute",
    right: "0.8rem",
    top: "0.6rem"
  },
  desktopLogo: {
    textAlign: "left"
  },
  desktopTab: {
    textAlign: "left"
  }
}));
