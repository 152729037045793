import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

import ClinicStartImage from "./components/ClinicStartImage";
import ClinicStartTextPanel from "./components/ClinicStartTextPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1240px",
      margin: "0 auto 6rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "90%",
      margin: "0 auto"
    },
  }),
);

function ClinicStart() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {!phoneMatch && <div className="circle-179" style={{ zIndex: -1 }} />}
      <Grid container={true} justify="center" alignItems="center">
        {!phoneMatch &&
          <Grid item={true} xs={12} sm={12} md={6}>
            <ClinicStartImage />
          </Grid>
        }
        <Grid item={true} xs={12} sm={12} md={6}>
          <ClinicStartTextPanel />
        </Grid>
        {phoneMatch &&
          <Grid item={true} xs={12} sm={12} md={6}>
            <ClinicStartImage />
          </Grid>
        }
      </Grid>
      {phoneMatch && <div className="circle-49" style={{ zIndex: -1, margin: "-6.6rem 0rem 0rem -1rem" }} />}
    </section>
  );
}

export default ClinicStart;
