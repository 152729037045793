import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";

interface ComponentProps {
  iconUrl: string;
  title: string;
  description: string;
}

function PartnerBenefitCard(props: ComponentProps) {
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <Box style={{ margin: `${phoneMatch ? "1rem auto 0" : "7px auto"}`, maxWidth: `${phoneMatch ? "343px" : "268px"}`, overflow: "hidden", height: `${phoneMatch ? "360px" : "386px"}` }} boxShadow={3} bgcolor="background.paper">
      <Box>
        <Box marginTop={1} marginBottom={4}>
          <i />
          <img src={props.iconUrl} alt="icon" style={{ marginTop: "1rem" }} />
        </Box>
        <Box marginTop={2} marginBottom={2}>
          <ResponsiveTypography className={`typo typo-weight-bold`} sizes={[18, 18, 18]} variant="body1">
            <span style={{ lineHeight: "23px", letterSpacing: "0.02em", maxWidth: `${phoneMatch ? "95%" : "182px"}`, display: "block", margin: "0 auto" }}>{props.title}</span>
          </ResponsiveTypography>
        </Box>
        <Typography style={{ color: "#727171", margin: "0 auto", fontSize: `${phoneMatch ? "16px" : "14px"}`, fontWeight: "normal", lineHeight: `${phoneMatch ? "24px" : "21px"}`, maxWidth: `${phoneMatch ? "299px" : "223px"}`, letterSpacing: "0.02em" }}>{props.description}</Typography>
      </Box>
    </Box>
  );
}

export default PartnerBenefitCard;
