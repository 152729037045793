import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useStyles } from "./styles";

function PartnerBenefitTitle() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <ResponsiveTypography
        sizes={[36, 36, 24]}
        className="typo typo-weight-bold color color-black"
        variant="h2"
      >
        {t("pages.partner.benefit.title")}
      </ResponsiveTypography>
    </section>
  );
}

export default PartnerBenefitTitle;
