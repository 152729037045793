import { createMuiTheme, ThemeOptions } from "@material-ui/core";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#FF8838",
    },

    text: {
      primary: "#201E19",
    },
  },
  typography: {
    fontFamily: "Mulish, Nunito, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h3: {
      fontWeight: 600,
      fontSize: "3.285rem",
      lineHeight: 1.37,
    },
    body1: {
      // fontWeight: 600,
    },
  },
};

export default createMuiTheme(themeOptions);
