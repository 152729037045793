import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import PartnerReasonCard from "./components/PartnerReasonCard";
import PartnerReasonTitle from "./components/PartnerReasonTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "3rem auto 0",
      padding: "3rem 0"
    },
    phoneRoot: {
      textAlign: "center",
      backgroundColor: "#F8F8F5",
      margin: "7rem auto 0"
    },
    desktopPanel: {
      maxWidth: "1210px",
      margin: "0 auto"
    },
    phonePanel: {
      maxWidth: "100%",
      margin: "4rem auto 3rem"
    },
    secondIconBar: {
      maxWidth: "80%",
      margin: "0 auto"
    }
  }),
);

function PartnerReason() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true}>
        <PartnerReasonTitle />
      </Grid>
      <div className={phoneMatch ? classes.phonePanel : classes.desktopPanel}>
        <Grid container={true} justify="center">
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerReasonCard iconUrl="/integration_icon.svg" title={t("pages.partner.reason.item1.title")} description={t("pages.partner.reason.item1.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerReasonCard iconUrl="/effortless_icon.svg" title={t("pages.partner.reason.item2.title")} description={t("pages.partner.reason.item2.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerReasonCard iconUrl="/boost_icon.svg" title={t("pages.partner.reason.item3.title")} description={t("pages.partner.reason.item3.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={3}>
            <PartnerReasonCard iconUrl="/target.svg" title={t("pages.partner.reason.item4.title")} description={t("pages.partner.reason.item4.content")} />
          </Grid>
        </Grid>
        <Grid container={true} justify="center" className={phoneMatch ? `` : classes.secondIconBar}>
          <Grid item={true} xs={12} sm={6} md={4}>
            <PartnerReasonCard iconUrl="/expertise_icon.svg" title={t("pages.partner.reason.item5.title")} description={t("pages.partner.reason.item5.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4}>
            <PartnerReasonCard iconUrl="/transparency_icon.svg" title={t("pages.partner.reason.item6.title")} description={t("pages.partner.reason.item6.content")} />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4}>
            <PartnerReasonCard iconUrl="/team_icon.svg" title={t("pages.partner.reason.item7.title")} description={t("pages.partner.reason.item7.content")} />
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default PartnerReason;
