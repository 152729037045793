import { AppBar, IconButton, useMediaQuery, Grid } from "@material-ui/core";
import Logo from "components/atoms/logo/Logo";
import { useStyles } from "./styles";
import { HeaderButtons } from "./components/HeaderButtons";
import { HeaderTabs } from "./components/HeaderTabs";

type HeaderProps = {
  onMenuClick: () => void;
};

const Header: React.FC<HeaderProps> = ({ onMenuClick }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width: 1200px)");

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      className={classes.root}
    >
      {
        // phone
        !matches ?
          <Grid
            container={true}
            className={classes.phoneMain}
            alignItems="center">
            <Grid item={true} xs={6} sm={6}>
              <div><Logo /></div>
            </Grid>
            <Grid item={true} xs={6} sm={6} className={classes.icon}>
              <div>
                <IconButton onClick={onMenuClick}>
                  <img src="/menu_icon.svg" alt="menu_icon" />
                </IconButton>
              </div>
            </Grid>
          </Grid> :
          // desktop
          <Grid container={true} className={classes.main} justify="center" alignItems="center">
            <Grid item={true} md={2} className={classes.desktopLogo}>
              <div><Logo /></div>
            </Grid>
            <Grid item={true} md={6} className={classes.desktopTab}>
              <div><HeaderTabs /></div>
            </Grid>
            <Grid item={true} md={4}>
              <div style={{ marginRight: "-11rem" }}><HeaderButtons /></div>
            </Grid>
          </Grid>
      }
    </AppBar>
  );
};

export default Header;
