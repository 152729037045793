import React from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { Divider, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

interface ComponentProps {
  question: string;
  answer: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      width: '75%',
      textAlign: 'left',
      margin: "0 auto",
      paddingBottom: "15px"
    },
    phoneRoot: {
      width: '90%',
      textAlign: 'left',
      margin: "0 auto",
      paddingBottom: "15px"
    },
    panel: {
      backgroundColor: "#F8F8F5",
      borderRadius: "0",
      boxShadow: "none"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

function PatientAnswerAccordian(props: ComponentProps) {
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Accordion id="accordion_faq" className={classes.panel} expanded={expanded === 'panel1'} square={true} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={
            <Icon>
              <img src="arrow_circle_down.svg" />
            </Icon>
          }
          aria-controls="panel4bh-content"
          id="panel1bh-header"
        >
          <ResponsiveTypography className="typo"
            sizes={[16, 16, 16]}
            variant="body1">
            {props.question}
          </ResponsiveTypography>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <ResponsiveTypography className="typo"
            sizes={[14, 14, 14]}
            variant="body1">
            {props.answer}
          </ResponsiveTypography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PatientAnswerAccordian;
