import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Box, Typography, Popover } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      position: "relative",
      width: '1110px',
      textAlign: 'center',
      margin: "0 auto",
      paddingBottom: "15px",
      backgroundColor: "#F8F8F5",
      zIndex: 1,
    },
    phoneRoot: {
      position: "relative",
      width: '95%',
      textAlign: 'center',
      margin: "14rem auto 0",
      paddingBottom: "15px",
      backgroundColor: "#F8F8F5",

    },
    label: {
      color: "white",
      fontSize: 14,
    },
    contactButton: {
      borderRadius: 0,
      width: "160px",
      height: "56px",
      margin: "3rem 0 0"
    },
    content: {
      margin: "2rem 0"
    },
    desktopText: {
      margin: "3rem 0",
      textAlign: "left"
    },
    phoneText: {
      margin: "11rem auto 1.5rem",
      maxWidth: "22rem"
    },
    desktopImg: {
      position: "absolute",
      margin: "-6.5rem 0rem 0rem 19rem"
    },
    phoneImg: {
      position: "absolute",
      transform: "scale(0.7)",
      margin: "-6.2rem 0rem 0rem 0.7rem"
    },
    buttonHoverLabel: {
      fontFamily: "Mulish",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.02em"
    }
  }),
);

function HomeFooter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <img src="/mailBox.svg" className={phoneMatch ? classes.phoneImg : classes.desktopImg} />
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} md={12} style={{ marginTop: "3rem" }}>
          <ResponsiveTypography
            sizes={[32, 32, 24]}
            className="typo typo-weight-bolder"
            variant="h4"
            align="center"
          >
            <span style={{
              display: "block",
              width: `${phoneMatch ? "290px" : "100%"}`,
              margin: "0 auto",
            }}>{t("pages.home.footer.title")}</span>
          </ResponsiveTypography>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ width: `${phoneMatch ? "200px" : "234px"}`, height: `${phoneMatch ? "48px" : "56px"}`, color: 'white', paddingRight: '3px', borderRadius: '0', marginTop: '30px', marginBottom: '30px' }}
                  variant="contained"
                  color="primary"
                  {...bindTrigger(popupState)}
                >
                  <span style={{ paddingTop: "13px", paddingBottom: "13px", marginRight: `${phoneMatch ? "2rem" : "3rem"}`, fontSize: "14px" }}>{t("pages.home.footer.buttonLabel")}</span>
                  {phoneMatch && <Divider orientation="vertical" flexItem style={{ height: "30px", margin: "auto 0" }} />}
                  {!phoneMatch && <Divider orientation="vertical" flexItem />}
                  <ArrowDropDownIcon style={{ marginLeft: "1rem" }} />
                </Button>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Box p={3} style={{ width: `${phoneMatch ? "12.5rem" : "14.5rem"}`, padding: "24px 0", borderRadius: "0" }}>
                    <Typography>
                      <Link to="/clinics">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content1")}</span>
                      </Link>
                    </Typography>
                    <Typography>
                      <Link to="/patients">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content2")}</span>
                      </Link>
                    </Typography>
                    <Typography>
                      <Link to="/partners">
                        <span className={classes.buttonHoverLabel}>{t("pages.home.title.button.content3")}</span>
                      </Link>
                    </Typography>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
      </Grid>
    </section>
  );
}

export default HomeFooter;