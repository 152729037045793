import { makeStyles, StyleRules } from '@material-ui/core';

export const styles: StyleRules = {
    root: {
        borderRadius: 2
    }
};

export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 2,

    },
    outlined: {
        borderColor: theme.palette.text.primary
    },
    outlinedPrimary: {
        borderColor: theme.palette.primary.main
    },
    contained: {
        boxShadow: 'none',
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
    },
    containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    }
}));