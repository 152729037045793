import ClinicLogo from "./components/clinic-logo/ClinicLogo";
import ClinicPercentage from "./components/clinic-percentage/ClinicPercentage";
import ClinicPayment from "./components/clinic-payment/ClinicPayment";
import ClinicCardFirst from "./components/clinic-card-section-1st/ClinicCardFirst";
import ClinicCardSecond from "./components/clinic-card-section-2nd/ClinicCardSecond";
import ClinicFocus from "./components/clinic-focus/ClinicFocus";
import ClinicStart from "./components/clinic-start/ClinicStart";
import ClinicSolution from "./components/clinic-solution/ClinicSolution";
import ClinicFooter from "./components/clinic-footer/ClinicFooter";

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      // maxWidth: "1440px",
      // margin: "0 auto",
      overflow: "hidden"
    }
  }),
);

function ClinicPage() {
  const classes = useStyles();

  return (
    <section className={classes.desktopRoot}>
      <ClinicLogo />
      <ClinicPercentage />
      <ClinicPayment />
      <ClinicCardFirst />
      <ClinicFocus />
      <ClinicStart />
      <ClinicCardSecond />
      <ClinicSolution />
      <ClinicFooter />
    </section>
  );
}

export default ClinicPage;