import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      position: "relative",
      zIndex: 1,
      backgroundColor: "#F8F8F5",
      maxWidth: "1110px",
      margin: "-2rem auto"
    },
    phoneRoot: {
      position: "relative",
      zIndex: 1,
      backgroundColor: "#F8F8F5",
      maxWidth: "94%",
      margin: "10rem auto 0",
      paddingTop: "5rem"
    },
    title: {
    },
    desktopButton: {
      color: "white",
      width: "236px",
      height: "56px",
      borderRadius: 0,
      margin: "2rem 0"
    },
    phoneButton: {
      color: "white",
      width: "212px",
      height: "48px",
      borderRadius: 0,
      margin: "1.5rem 0"
    },
    desktopText: {
      marginLeft: "5rem",
    },
    phoneText: {
      marginLeft: "1.5rem",
      marginTop: "30px",
      width: "303px"
    },
    desktopImage: {
      marginLeft: "3rem"
    },
    phoneImage: {
      position: "absolute",
      top: "-3.85rem",
      right: "0",
      width: "60%",
    }
  }),
);

function ClinicFooter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={12} sm={12} md={8}>
          <div className={phoneMatch ? classes.phoneText : classes.desktopText}>
            <ResponsiveTypography
              sizes={[32, 32, 24]}
              className={`typo typo-weight-bolder ${classes.title}`}
              variant="h4"
            >
              {t("pages.clinic.footer.title")}
            </ResponsiveTypography>
            <Link to="/contact-us">
              <Button
                variant="contained"
                color="primary"
                className={phoneMatch ? classes.phoneButton : classes.desktopButton}
              >
                <ResponsiveTypography
                  sizes={[14, 14, 14]}
                  className={`typo`}
                  variant="h1"
                >
                  {t("pages.clinic.footer.button.label")}
                </ResponsiveTypography>
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={4}>
          <img className={phoneMatch ? classes.phoneImage : classes.desktopImage} src="/pilot.svg" alt="pilot" />
        </Grid>
      </Grid>
    </section>
  );
}

export default ClinicFooter;
