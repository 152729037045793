import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: '13px 16px',
        backgroundColor: theme.palette.common.white
    },
    select: {
        display: 'flex'
    },
    input: {
        borderRadius: 2,
        '& > fieldset': {
            border: `1px solid #EBEBEB`,
            boxSizing: 'border-box',
            transition: `all 0.2s ${theme.transitions.easing.easeIn}`,
            '&:hover': {
                borderColor: theme.palette.text.primary
            }
        },
        marginTop: 10,
    },
    inputUnderline: {
        '&::before, &::after': {
            display: 'none'
        }
    },
    focused: {
        borderColor: theme.palette.text.primary
    },
    error: {
        borderColor: theme.palette.error.main
    },
    outlined: {
        // border: `1px solid ${theme.palette.text.primary}`
    },
    arrow: {
        position: "absolute",
        right: 20,
        top: "50%",
        transform: "translate(0, -50%)",
    }
}));