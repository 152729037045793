import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

import ClinicSolutionImage from "./components/ClinicSolutionImage";
import ClinicSolutionTextPanel from "./components/ClinicSolutionTextPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "1110px",
      margin: "8rem auto 10rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "90%",
      margin: "0 auto"
    },
  }),
);

function ClinicSolution() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <Grid container={true} justify="center" alignItems="center">
        {!phoneMatch &&
          <Grid item={true} xs={12} sm={12} md={6}>
            <ClinicSolutionImage />
          </Grid>
        }
        <Grid item={true} xs={12} sm={12} md={6}>
          <ClinicSolutionTextPanel />
        </Grid>
        {phoneMatch &&
          <Grid item={true} xs={12} sm={12} md={6}>
            <ClinicSolutionImage />
          </Grid>
        }
      </Grid>
    </section>
  );
}

export default ClinicSolution;
