import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "611px",
      maxHeight: "344px"
    },
    phoneRoot: {
      margin: "0 auto 3rem",
      transform: "scale(1.3)"
    },
    desktopImage: {

    },
    phoneImage: {
      width: "100%"
    }
  }),
);

function ClinicStartImage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <div className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      <img className={phoneMatch ? classes.phoneImage : classes.desktopImage} src="/meeting.png" alt="focus_image" style={{ width: "100%" }} />
    </div>
  );
}

export default ClinicStartImage;