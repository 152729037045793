import { Box, Typography } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link, useHistory } from "react-router-dom";
import { RouterPath } from "constants/routes.constant";
import FooterLogo from "./FooterLogo";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import { useTranslation } from "react-i18next";
import { useStyles } from './styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

function Footer() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <footer
      className={
        `${classes.root} 
        footer 
        ${history.location.pathname === RouterPath.Home ?
          "home-page" : ""
        }`}
    >
      <div className={phoneMatch ? classes.phoneMain : classes.desktopMain}>
        <Box
          justifyContent="space-between"
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          className="inner-content"
        >
          <div className="footer-block">
            <FooterLogo />
            <address style={{ fontStyle: "normal" }}>
              <a className="flex align-items-center" href={RouterPath.ContactUs}>
                <PhoneIcon color="primary" />
                {t("footer.phoneNumber")}
              </a>
              <a className="flex align-items-center" href={RouterPath.ContactUs}>
                <MailIcon color="primary" />
                {t("footer.mailAddress")}
              </a>
            </address>
            <Box className="footer-socail" display="flex" alignItems="center" mt={8}>
              <a href="https://www.facebook.com/befinamba"><img id="footer_facebook" src="/facebookIcon.svg" alt="facebookIcon" className={classes.image} /></a>
              <a href="https://twitter.com/Finamba1"><TwitterIcon id="footer_twitter" color="primary" className={classes.twitterIcon} /></a>
              <a href="https://www.instagram.com/befinamba/"><InstagramIcon id="footer_instagram" color="primary" /></a>
            </Box>
          </div>
          <div className="footer-block">
            <Typography
              className="typo typo-weight-bold"
              color="primary"
              gutterBottom={true}
            >
              {t("footer.about.title")}
            </Typography>
            <ol>
              <li>
                <Link to={RouterPath.AboutUs}>{t("footer.about.text1")}</Link>
              </li>
              <li>
                <Link to={RouterPath.ContactUs}>{t("footer.about.text2")}</Link>
              </li>
              {/*<li>{t("footer.about.text3")}</li>
              <li>{t("footer.about.text4")}</li>
              <li>{t("footer.about.text5")}</li>
            <li>{t("footer.about.text6")}</li>*/}
            </ol>
          </div>
            
          <div className="footer-block">
            {/*
            <Typography
              className="typo typo-weight-bold"
              color="primary"
              gutterBottom={true}
            >
              {t("footer.tools.title")}
            </Typography>
            <ol>
              <li>{t("footer.tools.text1")}</li>
              <li>{t("footer.tools.text2")}</li>
              <li>{t("footer.tools.text3")}</li>
              <li>{t("footer.tools.text4")}</li>
              <li>{t("footer.tools.text5")}</li>
              <li>{t("footer.tools.text6")}</li>
            </ol>
             */}
          </div>
           
          <div className="footer-block">
            {/*
            <Typography
              className="typo typo-weight-bold"
              color="primary"
              gutterBottom={true}
            >
              {t("footer.legal.title")}
            </Typography>
            <ol>
              <li>{t("footer.legal.text1")}</li>
              <li>{t("footer.legal.text2")}</li>
              <li>{t("footer.legal.text3")}</li>
            </ol>
            */}
          </div>
          
          <div className={`footer-block ${classes.how}`} >
            <Typography
              className="typo typo-weight-bold"
              color="primary"
              gutterBottom={true}
            >
              {t("footer.how.title")}
            </Typography>
            <ResponsiveTypography
              sizes={[16, 16, 16]}
            >
              {t("footer.how.text")}
            </ResponsiveTypography>
          </div>
        </Box>
        <Box mt={9}>
          {!phoneMatch && <p>{t("footer.copyright")}</p>}
        </Box>
      </div>
    </footer>
  );
}

export default Footer;
