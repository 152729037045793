import { Grid, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";
import { PhoneOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "left",
      maxWidth: "1110px",
      margin: "1rem auto -4rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "90%",
      margin: "4rem auto",
    },
    desktopLogoContent: {
      margin: "2rem 0",
      maxWidth: "453px",
    },
    phoneLogoContent: {
      margin: "2rem 0"
    },
    desktopLogoButton: {
      color: "white",
      width: "223px",
      height: "56px",
      borderRadius: 0
    },
    phoneLogoButton: {
      color: "white",
      width: "222px",
      height: "48px",
      borderRadius: 0
    },
    desktopLogoImg: {
      maxWidth: "600px",
      maxHeight: "600px"
    },
    phoneLogoImg: {
      width: "100%",
      transform: "scale(1.3)",
      overflowX: "hidden",
      margin: "0 auto"
    },
  }),
);

function ClinicLogo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {!phoneMatch &&
        <>
          <div className="circle-36" style={{ margin: "2rem 0rem 0rem -4rem" }} />
          <div className="circle-22" style={{ margin: "2rem 0rem 0rem 74.5rem" }} />
        </>
      }
      {phoneMatch && <div className="circle-24" style={{ margin: "-1rem 0rem 0rem -5px" }} />}
      <Grid
        container={true}
        spacing={3}
        alignItems="center"
        justify="space-between"
      >
        <Grid item={true} xs={12} sm={12} md={6}>
          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bolder"
            variant="h4"
          >
            <span style={{ lineHeight: `${phoneMatch ? "40px" : "56px"}` }}>{t("pages.clinic.title1")}</span>
          </ResponsiveTypography>

          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bolder color color-black"
            variant="h2"
            color="primary"
          >
            <span style={{ lineHeight: `${phoneMatch ? "40px" : "56px"}` }}>{t("pages.clinic.title2")}</span>
          </ResponsiveTypography>

          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bolder color color-black"
            variant="h2"
          >
            <span style={{ lineHeight: `${phoneMatch ? "40px" : "56px"}` }}>{t("pages.clinic.title3")}</span>
          </ResponsiveTypography>

          <div className={phoneMatch ? classes.phoneLogoContent : classes.desktopLogoContent}>
            <ResponsiveTypography className="typo typo-content" sizes={[20, 20, 16]} variant="body1">
              <span style={{ fontWeight: 400 }}>{t("pages.clinic.title.content")}</span>
            </ResponsiveTypography>
          </div>
          <Link to="/contact-us">
            <Button
              variant="contained"
              color="primary"
              className={phoneMatch ? classes.phoneLogoButton : classes.desktopLogoButton}
            >
              <span>{t("pages.clinic.title.button.label")}</span>
            </Button>
          </Link>
        </Grid>
        {phoneMatch && <div className="circle-22" style={{ margin: "4rem 0rem 0rem 20rem" }} />}
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={6}
        >
          <img className={phoneMatch ? classes.phoneLogoImg : classes.desktopLogoImg} src="/clinic_logo.svg" alt="clinic_logo" />
        </Grid>
      </Grid>
      {phoneMatch &&
        <>
          <div className="circle-18-06" />
          <div className="circle-60" style={{ margin: "12px 0rem 0rem 0rem", width: "50%" }} />
        </>
      }
    </section >
  );
}

export default ClinicLogo;
