import ContactUsForm from "./components/contact-us-form/ContactUsForm";
import ContactUsSocial from "./components/contact-social/ContactSocial";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";

function ContactUsPage() {
  const { t } = useTranslation();

  return (
    <div className="contact-us-page content-wrapper">
      <div className="contact-us">
        <ResponsiveTypography
          sizes={[46, 46, 24]}
          variant="h3"
          color="textPrimary"
          gutterBottom={true}
          fontWeight={700}
        >
          {t("pages.contactUs.title")}
        </ResponsiveTypography>
        <section>
          <ContactUsForm />
        </section>
        <section>
          <ContactUsSocial />
        </section>
      </div>
    </div>
  );
}

export default ContactUsPage;
