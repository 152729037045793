import { useTranslation } from "react-i18next";
import PartnerLogo from "./components/partner-logo/PartnerLogo";
import PartnerBuy from "./components/partner-buy/PartnerBuy";
import PartnerReason from "./components/partner-reason/PartnerReason";
import PartnerBenefit from "./components/partner-benefit/PartnerBenefit";
import PartnerIcons from "./components/partner-icons/PartnerIcons";
import PartnerFooter from "./components/partner-footer/PartnerFooter";

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      maxWidth: "100%",
      overflow: "hidden",
      margin: "0 auto"
    }
  }),
);

function PartnerPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <section className={classes.desktopRoot}>
      <PartnerLogo />
      <PartnerBuy />
      <PartnerReason />
      <PartnerBenefit />
      <PartnerIcons />
      <PartnerFooter />
    </section>
  );
}

export default PartnerPage;