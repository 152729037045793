import React from 'react';
import MaterialButton, { ButtonProps } from '@material-ui/core/Button';

import { useStyles } from './styles';

function Button(props: ButtonProps) {
    const classes = useStyles();

    return (
        <MaterialButton 
            classes={{
                root: classes.root,
                outlined: classes.outlined,
                outlinedPrimary: classes.outlinedPrimary,
                contained: classes.contained,
                containedPrimary: classes.containedPrimary
            }}
            {...props} 
        />
    );
}

export default Button;
