import { Grid, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopRoot: {
      textAlign: "left",
      maxWidth: "1110px",
      margin: "1rem auto 4rem"
    },
    phoneRoot: {
      textAlign: "center",
      maxWidth: "85%",
      margin: "4rem auto 9rem"
    },
    desktopLogoContent: {
      maxWidth: "28rem",
      margin: "2rem 0"
    },
    phoneLogoContent: {
      maxWidth: "28rem",
      margin: "2rem auto 2rem"
    },
    desktopLogoButton: {
      color: "white",
      width: "223px",
      height: "56px",
      borderRadius: 0
    },
    phoneLogoButton: {
      color: "white",
      width: "201px",
      height: "48px",
      borderRadius: 0
    },
    desktopLogoImg: {
      position: "relative",
      maxWidth: "600px",
      maxHeight: "600px"
    },
    phoneLogoImg: {
      width: "100%",
      transform: "scale(1.3)",
      marginTop: "4rem",
      overflowX: "hidden"
    },
  }),
);

function PatientLogo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { phoneMatch } = useCommonScreensMediaQuery();

  return (
    <section className={phoneMatch ? classes.phoneRoot : classes.desktopRoot}>
      {!phoneMatch && <div className="circle-44" style={{ margin: "1rem 0rem 0rem -4rem" }} />}
      {phoneMatch && <div className="circle-24" style={{ margin: "-1.7rem 0rem 0rem -0.5rem" }} />}
      <Grid
        container={true}
        spacing={3}
        alignItems="center"
        justify="space-between"
      >
        <Grid item={true} xs={12} sm={12} md={6}>
          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bold"
            variant="h4"
          >
            {t("pages.patient.title1")}
          </ResponsiveTypography>

          {phoneMatch ?
            <ResponsiveTypography
              sizes={[48, 48, 32]}
              className="typo typo-weight-bold color color-black typo-partner-phone-logo"
              variant="h2"
            >
              {t("pages.patient.title2")}
            </ResponsiveTypography> :
            <ResponsiveTypography
              sizes={[48, 48, 32]}
              className="typo typo-weight-bold color color-black typo-partner-logo"
              variant="h2"
            >
              {t("pages.patient.title2")}
            </ResponsiveTypography>
          }

          <ResponsiveTypography
            sizes={[48, 48, 32]}
            className="typo typo-weight-bold"
            variant="h4"
            color="primary"
          >
            {t("pages.patient.title3")}
          </ResponsiveTypography>

          {phoneMatch ?
            <div className={classes.phoneLogoContent}>
              <ResponsiveTypography className="typo typo-content" sizes={[20, 20, 14]} variant="body1">
                {t("pages.patient.title.content")}
              </ResponsiveTypography>
            </div> :
            <div className={classes.desktopLogoContent}>
              <ResponsiveTypography className="typo typo-content" sizes={[20, 20, 14]} variant="body1">
                {t("pages.patient.title.content")}
              </ResponsiveTypography>
            </div>
          }
          {phoneMatch && <div className="circle-18" style={{ margin: "-1rem 0rem 0rem 19rem" }} />}
          {/*
          <Link to="/aspatient">
            <Button
              variant="contained"
              color="primary"
              className={phoneMatch ? classes.phoneLogoButton : classes.desktopLogoButton}
            >
              <ResponsiveTypography className="typo" sizes={[14, 14, 14]} variant="body1">
                {t("pages.patient.title.button.label")}
              </ResponsiveTypography>
            </Button>
          </Link>
          */}
          <Button
              variant="contained"
              color="primary"
              className={phoneMatch ? classes.phoneLogoButton : classes.desktopLogoButton}
            >
              <ResponsiveTypography className="typo" sizes={[14, 14, 14]} variant="body1">
                {t("pages.patient.applysoon")}
              </ResponsiveTypography>
            </Button>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={6}
        >
          <img className={phoneMatch ? classes.phoneLogoImg : classes.desktopLogoImg} src="/patient-logo.svg" alt="human" />
          {!phoneMatch && <div className="circle-22" style={{ margin: "-19rem 0rem 0rem 39rem " }} />}
          {!phoneMatch && <div className="circle-22" style={{ margin: "0rem 0rem 0rem -40.7rem" }} />}
          {phoneMatch && <div className="circle-22" style={{ margin: "-8rem 0rem 0rem -0.7rem" }} />}
        </Grid>

      </Grid>
    </section >
  );
}

export default PatientLogo;
