import AboutUsHero from "./components/about-us-hero/AboutUsHero";
import AboutUsValues from "./components/about-us-values/AboutUsValues";

function AboutUsPage() {
  return (
    <div className="about-us">
      <AboutUsHero />
      <AboutUsValues />
    </div>
  );
}

export default AboutUsPage;
