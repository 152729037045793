import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ResponsiveTypography } from "components/atoms/ResponsiveTypography";
import { useCommonScreensMediaQuery } from "utils/hooks/useCommonScreensMediaQuery";

interface ComponentProps {
  iconUrl: string;
  title: string;
  description: string;
}

function PatientReasonCard(props: ComponentProps) {
  const { phoneMatch } = useCommonScreensMediaQuery();
  return (
    <Box style={{ border: "0", marginRight: `${phoneMatch ? "" : "1rem"}`, marginBottom: "3rem" }}>
      <div>
        <i />
        <img src={props.iconUrl} alt="icon" />
      </div>
      <Box marginTop={4} marginBottom={2}>
        <ResponsiveTypography
          sizes={[20, 20, 18]}
          className={`typo typo-weight-bold`}
          variant="h4"
        >
          {props.title}
        </ResponsiveTypography>
      </Box>
      <ResponsiveTypography className={`typo`} sizes={[16, 16, 16]} variant="body1">
        <span style={{ lineHeight: "24px", textAlign: "center", letterSpacing: "0.02em", width: `${phoneMatch ? "343px" : "240px"}` }}>{props.description}</span>
      </ResponsiveTypography>
    </Box>
  );
}

export default PatientReasonCard;
